@use '../variables' as *
@use '../_mixins' as *

.post__link:hover .post__img
    transform: scale(1.2)

.post__title
    font-size: 14px
    color: $grey-600
    padding-top: 30px

.post__media
    width: 100%
    overflow: hidden

.post__img
    width: 100%
    object-fit: cover
    transition: all 2s ease

.post__link
    text-decoration: none

    .post__img,
    .post__title,
    .post__media
        pointer-events: none

@include larger
    .post__title
        padding-top: 20px

@include small
    .post__title
        padding-top: 10px
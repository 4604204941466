@use '../../_variables' as *
@use '../../_mixins' as *

.parceiros
    position: relative
    width: 100%
    background: $grey-200
    padding: 50px 90px 150px

.parceiros__container
    display: grid
    grid-template-columns: 340px 1fr
    width: 100%
    padding-top: 190px

.parceiros__title
    position: relative
    display: inline-flex
    justify-content: center
    align-items: center
    width: 100%
    height: 300px
    font-size: 30px
    font-weight: 400
    color: $grey-800
    padding: 0 40%
    line-height: 1.3
    z-index: 1

    &::after
        content: ''
        display: block
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 155%
        border: 20px solid $color-primary
        transform: translateY(-35%)

.parceiros__list
    display: grid
    grid-template-columns: repeat(3, 1fr)

.parceiros__item
    width: 100%
    max-height: 150px

.parceiros__link
    display: flex
    justify-content: center
    width: 100%
    height: 100%
    pointer-events: none

    &:hover .parceiros__img
        opacity: 1

.parceiros__img
    width: 100%
    max-width: 150px
    max-height: 180px
    object-fit: contain
    mix-blend-mode: darken
    opacity: 1
    transition: all 300ms ease

@include larger
    .parceiros
        padding-bottom: 90px

    .parceiros__container
        grid-template-columns: 205px 1fr
        column-gap: 40px
        padding-top: 100px

    .parceiros__title
        height: 200px
        font-size: 18px
        padding: 70px 20% 0

        &::after
            width: 100%
            height: 168%
            border: 10px solid $color-primary
            transform: translateY(-33.5%)

    .parceiros__list
        row-gap: 30px

    .parceiros__item
        height: 80px

    .parceiros__img
        max-width: 90px
        max-height: 100px

@include large
    .parceiros__container
        max-width: 1405px
        margin-left: 60px

@include medium
    .parceiros
        padding: 50px 40px 90px 70px

    .parceiros__container
        max-width: none
        margin-left: auto

@include small
    .parceiros
        padding: 20px 0

    .parceiros__container
        grid-template-columns: 1fr
        padding: 0 10%

    .parceiros__title
        max-width: 180px
        justify-self: center
        text-align: center
        line-height: 1.5
        padding: 65px 30px

        &::after
            left: 50%
            height: 100%
            transform: translateX(-50%)

    .parceiros__list
        grid-template-columns: 1fr 1fr
        margin-top: 30px

    .parceiros__item
        height: 55px

    .parceiros__img
        max-width: 90px
@use '../_variables' as *
@use '../_mixins' as *

.logo
    position: relative
    width: 200px
    height: 50px
    
    span
        display: none

.logo__link
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

    &:hover .logo__icon
        color: $color-primary

.logo__icon
    width: 100%
    height: 100%
    color: $grey-800
    transition: all 300ms ease

@include larger
    .logo
        width: 160px
        height: 40px
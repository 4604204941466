@use '../_variables' as *
@use '../_mixins' as *

.form
    display: grid
    row-gap: 0.8rem
    background: $grey-200
    padding: 1.7rem 2rem

.form__columns
    display: grid
    column-gap: 3.45rem
    min-width: 0

.form__columns--2-1
    grid-template-columns: 2fr 1fr

.form__columns--1-2
    grid-template-columns: 1fr 2fr

.form__footer
    display: flex
    justify-content: space-between
    position: relative
    font-size: 0.75rem
    color: $grey-600
    font-weight: 400
    line-height: 2
    margin-top: 1rem

.form__action
    display: inline-flex
    justify-content: center
    align-items: center
    position: relative
    background: $color-primary
    font-family: $font-primary
    font-size: 0.75rem
    color: $white
    font-weight: 600
    line-height: normal
    letter-spacing: 0.165rem
    text-transform: uppercase
    cursor: pointer
    border: 0
    padding: 1rem 5rem
    transition: background 200ms ease
    overflow: hidden

    &:hover
        background: $color-tertiary

        .form__icon
            transform: translateX(20px)

    span:first-child
        position: relative
        transition: transform 200ms ease

.form__action--loading
    pointer-events: none

    .form__loading
        transform: translateY(-220%)

    span
        transform: translateY(-220%)

.form__icon
    position: absolute
    right: -30px
    margin-left: 1rem
    transition: transform 200ms ease

.form__aviso
    line-height: 1

.form__recaptcha
    position: relative
    justify-self: start
    background: $grey-250
    border-radius: 6px
    max-width: 70vw
    overflow: hidden
    padding: 0.25rem

.form__recaptcha--error
    box-shadow: 0px 0px 0px 1px $color-primary

.form__loading
    display: inline-flex
    position: absolute
    width: 75px
    height: 25px
    align-items: center
    bottom: -80%
    transition: transform 200ms ease

    &__dot
        position: absolute
        width: 13px
        height: 13px
        border-radius: 50%
        background: #fff
        animation-timing-function: cubic-bezier(0, 1, 1, 0)

        &:nth-child(1)
            left: 8px
            animation: form__loading1 0.6s infinite

        &:nth-child(2)
            left: 8px
            animation: form__loading2 0.6s infinite

        &:nth-child(3)
            left: 32px
            animation: form__loading2 0.6s infinite

        &:nth-child(4)
            left: 56px
            animation: form__loading3 0.6s infinite

.form__progress
    position: absolute
    left: 0
    bottom: -12px
    width: 100%
    height: 4px
    background: $grey-350
    opacity: 0
    transition: opacity 150ms ease

.form__progress--show
    opacity: 1

.form__bar
    position: absolute
    top: 0
    left: 0
    background: $color-primary
    width: 30%
    height: 100%
    transition: width 300ms ease

.form__modal.swal2-popup
    border-radius: 0
    padding: 2.5rem 1rem

.swal2-confirm.form__modal-button.swal2-styled
    background-color: $color-primary
    padding: 0.8rem 3rem
    border-radius: 0
    box-shadow: none
    transition: all 150ms ease

    &:hover
        background-color: $color-tertiary

    &:focus
        box-shadow: none

@keyframes form__loading1
    0%
        transform: scale(0)
    100%
        transform: scale(1)

@keyframes form__loading3
    0%
        transform: scale(1)
    100%
        transform: scale(0)

@keyframes form__loading2
    0%
        transform: translate(0, 0)
    100%
        transform: translate(24px, 0)

@include largest
    .form__columns
        column-gap: 1rem

    .form__columns--2-1
        grid-template-columns: 1.5fr 1fr

    .form__columns--1-2
        grid-template-columns: 1fr 1.5fr

@include larger
    .form__columns
        grid-template-columns: 1fr 1fr
        column-gap: 1rem

    .form__footer
        display: grid
        grid-template-columns: 1fr 1fr
        column-gap: 1rem

    .form__action
        min-width: 0

    .form__aviso
        font-size: 0.6875rem

@include medium
    .form
        width: calc(100% + 4rem)
        margin-left: -2rem

@include smaller
    .form__footer
        grid-template-columns: 1fr
        row-gap: 1.5rem
        justify-items: end
        margin-top: 0

    .form__aviso
        justify-self: start

    .form__columns
        grid-template-columns: 1fr
        row-gap: 0.8rem

@include smallest
    .form__action
        font-size: 0.6875rem

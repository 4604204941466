@use '../../_variables' as *
@use '../../_mixins' as *

.error
    background: $grey-200
    display: flex
    flex-direction: column
    height: 100vh
    justify-content: center

.error__container
    display: grid
    max-width: 960px
    grid-template-columns: 1fr 1fr

.error__info
    display: flex
    flex-direction: column
    align-items: flex-start
    color: $grey-600

.error__logo
    width: 150px
    margin-bottom: 60px

    svg
        color: $color-primary

    .logo__link:hover svg
        color: $grey-800

.error__title
    font-size: 40px
    font-weight: 300
    line-height: 1.2
    margin-bottom: 30px

.error__code
    font-size: 20px
    font-weight: 700
    line-height: 1.5

.error__message
    max-width: 300px
    font-size: 16px
    font-weight: 400
    line-height: 1.5
    margin-bottom: 45px

.error__action
    color: $grey-600
    border: 2px solid $color-primary
    padding: 0.9rem 1.3rem

.error__img
    position: relative
    display: flex
    justify-content: center

.error__porta
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

@include medium
    .error__title
        font-size: 32px

@include small
    .error__container
        grid-template-columns: 1fr

    .error__img
        display: none

@include smaller
    .error
        height: auto
        min-height: 100vh
        padding-top: 20px
        box-sizing: border-box

@use '../_variables' as *
@use '../_mixins' as *

.box
    display: flex
    align-items: center
    position: relative
    background: $grey-200
    font-size: 12px
    font-weight: 600
    color: $color-primary
    letter-spacing: 0.22em
    text-decoration: none
    border: 2px solid $color-primary
    padding: 4rem 6.25rem 4rem 2.5rem
    transition: all 300ms ease

    &::after
        content: ''
        display: block
        position: absolute
        top: 0
        right: 0
        background: $color-primary url('./img/arrow.png') no-repeat center
        width: 50px
        height: 50px
        transition: all 300ms ease

.box:hover
    border-color: $color-tertiary

    &::after
        background-color: $color-tertiary
        background-position: top 10px right 10px

    .box__icon
        color: $color-tertiary

.box__icon
    width: 35px
    height: 35px
    color: $grey-500
    margin-right: 35px
    transition: all 300ms ease

.box__label
    display: inline-flex
    align-items: center
    height: 45px
    line-height: 1.5
    border-left: 1px solid $grey-400
    padding-left: 35px

@include larger
    .box
        padding: 3rem 6.25rem 3rem 2.5rem 

    .box__icon
        margin-right: 20px

@include large
    .box__label
        max-width: 120px
        line-height: 1.5
        margin-top: 15px

@include medium
    .box
        padding: 3rem 2rem 3rem 2rem

    .box__label
        max-width: 150px
        padding-left: 25px

@include small
    .box
        flex-direction: column
        align-items: flex-start
        padding: 10% 10% 6%

        &::after
            width: 35px
            height: 35px
            background-size: 10px

    .box__icon
        width: 28px
        height: 28px
        margin-right: 0

    .box__label
        border-left: 0
        padding-left: 0

@include smalle
    .box__label
        max-width: none

@include smaller
    .box__icon
        width: 35px
        height: 35px


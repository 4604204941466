.placeholder
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover

.placeholder__image
    position: relative
    opacity: 0
    transition: all 300ms ease

.placeholder__image--show
    opacity: 1
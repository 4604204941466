@use '../_variables' as *
@use '../_mixins' as *

.header
    display: flex
    position: relative
    z-index: 5
    background: $grey-200
    height: 115px

.header__container
    display: flex
    width: 100%
    height: 100%
    padding: 0 90px
    box-sizing: border-box
    max-width: none

.header__logo
    height: 100%
    align-self: center

.header__social
    display: none

@include larger
    .header
        height: 80px

    .header__logo.logo
        width: 140px
        height: 40px

@include large
    .header__container
        padding: 0 60px

@include medium
    .header__social
        display: flex
        justify-content: flex-end
        height: 100%
        flex: 1

        .social__list
            display: grid
            grid-auto-flow: column
            align-items: center
            column-gap: 15px
            height: 100%

@include small
    .header
        height: 55px

    .header__container
        padding: 0

    .header__logo.logo
        width: 110px
        height: 36px
        margin-left: 60px

    .header__social
        display: none

@include smaller
    .header__logo.logo
        width: 100px
        height: 60px
        margin-left: 10vw
@forward './_banner.sass'
@forward './_vejamais.sass'
@forward './_sobre.sass'
@forward './_portfolio.sass'
@forward './_ambientes.sass'
@forward './_parceiros.sass'
@use '../../_variables' as *
@use 'sass:math'

.home__cinema
    display: flex
    justify-content: center
    align-items: center
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    padding: 10% 12%
    z-index: 5
    background: $grey-500
    pointer-events: none
    opacity: 0
    transform: scale(0.9)
    transition: all 300ms ease

    &--show
        opacity: 1
        transform: scale(1)
        pointer-events: all

.home__wrap
    position: relative
    width: 130vh
    padding-top: math.div(9, 16) * 130vh

.home__video
    position: absolute !important
    top: 0
    left: 0
    width: 100%
    height: 100%

.home__close
    position: absolute
    top: 5%
    right: 5%
    width: 60px
    height: 55px
    padding: 0

@media (max-aspect-ratio: 137/85)
    .home__wrap
        width: 100%
        padding-top: math.div(9, 16) * 100%
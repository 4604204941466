@use '../_variables' as *
@use '../_mixins' as *

.breadcrumb
    display: flex
    justify-content: flex-start
    background: $grey-500
    padding: 10px 20px

.breadcrumb__item,
.breadcrumb__link
    font-size: 12px
    color: $white
    font-weight: 400
    letter-spacing: 0.22em
    text-decoration: none

.breadcrumb__link:hover
    text-decoration: underline

.breadcrumb__item:not(:first-child)::before
    content: "/"
    margin: 0 4px

@include medium
    .breadcrumb__item,
    .breadcrumb__link
        font-size: 10px

@include smallest
    .breadcrumb__item,
    .breadcrumb__link
        font-size: 8px

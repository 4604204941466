@use '../_variables' as *

.uploader
    display: grid
    row-gap: 1rem
    justify-content: start
    justify-items: start

.uploader__field
    display: none

.uploader__action
    background: $color-primary
    font-family: $font-primary
    border: 0
    cursor: pointer
    color: $white
    padding: 0.75rem 1.25rem
    transition: background 150ms ease

    &:hover
        background: $color-tertiary
        color: $white

.uploader__informacoes
    display: grid
    row-gap: 1rem
    font-size: 0.75rem
    list-style-type: none

    code
        background: transparentize($color-primary, 0.85)
        color: $color-primary
        font-weight: 600
        border-radius: 6px
        padding: 0.1rem 0.25rem

.uploader__informacao
    line-height: 1.5

.uploader__arquivos
    display: grid
    row-gap: 0.3rem
    list-style-type: none

.uploader__arquivo
    display: flex
    justify-content: space-between
    background: $grey-300
    
.uploader__name
    display: inline-block
    font-size: 0.75rem
    padding: 0.5rem

.uploader__remover
    background: $color-primary
    color: $white
    font-weight: bold
    cursor: pointer
    border: 0
    height: 100%
    padding: 0 0.5rem
    transition: background 150ms ease

    &:hover
        background: $grey-800
@use '../_variables' as *
@use '../_mixins' as *

.button
    background: $color-primary
    font-size: 12px
    font-weight: 600
    letter-spacing: 0.22em
    color: $white
    cursor: pointer
    text-decoration: none
    border: 0
    padding: 20px 40px
    transition: all 300ms ease
    
    svg
        transition: all 300ms ease

    &:hover
        background: $grey-300
        color: $color-primary

        svg
            color: $color-primary

.button--alt
    position: relative
    background: transparent

    &:hover
        background: $color-primary
        color: $white

    &::after
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        z-index: -1
        border: 2px solid $white
        transition: all 200ms ease

@include larger
    .button
        padding: 15px 20px
@use '../_variables' as *

.social__list
    display: grid
    justify-content: center
    justify-items: center

.social__icon
    transition: all 150ms ease

.social__link:hover .social__icon
    color: $color-primary

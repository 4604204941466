@use '../_mixins' as *

.container
    width: 90%
    max-width: 1405px
    margin: 0px auto

@include larger
    .container
        width: 100%
        max-width: 1030px
        padding: 0 90px

@include small
    .container
        max-width: none
        padding: 0 60px

@include smaller
    .container
        padding: 0 10%
@use 'variables' as *

body
    font-family: $font-primary

::selection
    background-color: $color-primary
    color: #fff

.body--clipping
    overflow: hidden

[v-cloak]
    display: none

@use '../_variables' as *
@use '../_mixins' as *
@use 'sass:color'
@use 'sass:math'

.modal
    position: fixed
    top: 0
    left: 0
    z-index: 10
    width: 100vw
    height: 100vh
    background: transparent
    pointer-events: none
    opacity: 0
    transform: scale(0.9)
    overflow-y: auto
    transition: all 300ms ease

.modal--show
    background: $grey-600
    opacity: 1
    pointer-events: all
    transform: scale(1)

.modal__container
    display: flex
    flex-direction: column
    width: 100%
    padding: 40px 120px
    box-sizing: border-box

.modal__header
    display: flex
    justify-content: space-between
    align-items: center

.modal__title
    font-size: 40px
    color: $grey-300
    font-weight: 300
    letter-spacing: 0.4em
    text-transform: uppercase

.modal__close
    width: 60px
    height: 55px
    padding: 0

.modal__content
    display: flex
    flex-direction: column
    margin-top: 70px

.modal__slide
    width: 100%

.modal__media
    position: relative
    width: 100%
    height: calc(100vh - 260px)

.modal__img
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover

.modal__description
    display: inline-block
    width: 60%
    font-size: 14px
    color: $white
    line-height: 1.7
    margin-top: 30px

.modal__pagination.splide__pagination
    left: auto
    right: 0
    bottom: -35px
    padding: 0

.modal__page.splide__pagination__page
    background: $grey-500
    width: 35px
    height: 5px
    border-radius: 0
    transform: none
    opacity: 1
    transition: background 150ms ease

    &.is-active
        background: $color-primary
        transform: none

// .modal__arrows

.modal__arrow.splide__arrow
    left: -43px
    background: $color-primary
    width: 68px
    height: 68px
    border-radius: 0
    opacity: 1
    transform: translateX(-100%) translateY(-50%)

    &:disabled
        pointer-events: none
        background: $grey-800 !important

    &:hover
        opacity: 1

    svg
        width: 30px
        height: auto
        color: $white
        fill: transparent
        transition: all 150ms ease

.modal__arrow--prev.splide__arrow--prev
    &:hover svg
        transform: translateX(-5px) scaleX(1)

    svg
        transform: scaleX(1)

.modal__arrow--next.splide__arrow--next
    left: auto
    right: -43px
    transform: translateX(100%) translateY(-50%)

    &:hover svg
        transform: translateX(5px) scaleX(-1)

    svg
        transform: scaleX(-1)

@include large
    .modal__title
        font-size: 28px

@include medium
    .modal__container
        padding: 10% 10%

    .modal__arrows
        display: none

    .modal__description
        max-width: none
        width: 100%
        margin-top: 50px

@include small
    .modal__header
        flex-direction: column

    .modal__title
        order: 2
        margin-top: 10px

    .modal__close
        align-self: flex-end

    .modal__content
        margin-top: 50px

@include smaller
    .modal__title
        font-size: 20px

    .modal__close
        width: 50px
        height: 50px

@use '../../_variables' as *
@use '../../_mixins' as *

.portfolio .nav__action
    background: $grey-200

    &:hover
        background: $color-primary

.portfolio__header.header
    background: $grey-300

    .nav__link
        background-color: $grey-300
        background-image: linear-gradient(0deg, $color-primary 50%, $grey-300 50%)

    .nav__item--destaque .nav__link
        background-color: $grey-200
        background-image: linear-gradient(0deg, $color-primary 50%, $grey-200 50%)

.portfolio__content
    background: $grey-200

.portfolio__intro
    background: $grey-300
    padding: 0 90px 230px

.portfolio__title
    font-size: 212px
    color: $color-tertiary
    font-weight: 100
    letter-spacing: 0.065em
    text-align: center
    border: 3px solid $grey-200
    padding: 125px 0

.portfolio__list
    display: grid
    grid-template-columns: 1fr 1fr
    column-gap: 140px
    row-gap: 115px
    margin-top: -80px

.portfolio__pagination
    margin-top: 150px

@include larger
    .portfolio__content
        padding-bottom: 70px

    .portfolio__intro
        padding: 0 90px 150px

    .portfolio__title
        font-size: 140px
        padding: 80px 0

    .portfolio__list
        column-gap: 60px
        row-gap: 75px

    .portfolio__header.header
        .nav__link
            background: transparent

@include large
    .portfolio__title
        font-size: 110px
        padding: 60px 0

    .portfolio__intro
        padding: 0 60px 150px

@include medium
    .portfolio__content
        padding-bottom: 20px

    .portfolio__title
        font-size: 90px

@include small
    .portfolio__title
        padding: 40px 0

    .portfolio__intro
        padding: 0 60px 120px

    .portfolio__list
        margin-top: -60px
        row-gap: 50px

    .portfolio__content
        padding-bottom: 1

    .portfolio .footer
        padding-top: 0

@include smalle
    .portfolio__title
        font-size: 56px

    .portfolio__list
        grid-template-columns: 1fr

    .portfolio__content
        padding-bottom: 60px

@include smaller
    .portfolio__title
        font-size: 48px

    .portfolio__intro
        padding: 0 10% 180px

    .portfolio__list
        grid-template-columns: 1fr
        margin-top: -120px

    .portfolio__content
        padding-bottom: 80px

@include smallest
    .portfolio__title
        font-size: 36px

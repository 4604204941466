@use '../../_variables' as *
@use '../../_mixins' as *

.portfolio-home
    background: $grey-200

.portfolio-home__container
    width: 100%
    max-width: none
    padding: 10vh 90px 175px
    box-sizing: border-box

.portfolio-home__title
    position: absolute
    z-index: 0
    top: 0
    left: 0
    right: 0
    font-size: 110px
    font-weight: 100
    color: $grey-500
    text-align: center
    letter-spacing: 0.065em
    transform: translateY(50px)

.portfolio-home__frame
    position: relative
    background: $grey-200
    border: 20px solid $grey-300
    padding: 200px 0 120px
    z-index: 2

.portfolio-home__wrap
    width: calc(100vw - 127px)

.portfolio-home__list
    width: 100%
    display: grid
    grid-auto-flow: column
    column-gap: 55px

.portfolio-home__splide.splide
    position: static
    width: 100%

.portfolio-home__track.splide__track
    position: static

.portfolio-home__arrows
    display: flex
    position: absolute
    left: -20px
    bottom: -20px

.portfolio-home__arrow.splide__arrow
    position: static
    display: inline
    background: $grey-300
    width: auto
    height: 70px
    border: 1px solid $grey-200
    border-radius: 0
    align-items: center
    cursor: pointer
    opacity: 1
    padding: 20px 40px
    transform: translateY(0%)

    svg
        fill: none
        width: auto
        height: auto
        transform: scaleX(1)
        transition: all 300ms ease

    &:hover
        background: $color-primary

        svg
            color: $white

.portfolio-home__arrow--next.splide__arrow--next svg
    transform: scaleX(-1)

.portfolio-home__action
    display: inline-flex
    align-items: center
    height: 70px
    position: absolute
    right: -20px
    bottom: -20px

    svg
        margin-left: 45px

    &:hover
        color: $white
        background: $color-primary

        svg
            color: $white
            transform: rotate(180deg)

@include larger
    .portfolio-home__container
        padding-bottom: 105px

    .portfolio-home__frame
        border-width: 12px
        padding: 160px 0 140px

    .portfolio-home__title
        font-size: 80px
        letter-spacing: 0.3em
        transform: translateY(40px)

    .portfolio-home__arrows
        left: -12px
        bottom: -12px

    .portfolio-home__arrow.splide__arrow
        height: 55px
        padding: 20px 40px

        svg
            width: 35px

    .portfolio-home__action
        right: -12px
        bottom: -12px
        height: 55px
        padding: 15px 40px

        svg
            width: 16px
            margin-left: 20px

@include large
    .portfolio-home__container
        padding: 10vh 60px 105px

    .portfolio-home__frame
        border-width: 10px

    .portfolio-home__arrows
        left: -10px
        bottom: -10px

    .portfolio-home__arrow.splide__arrow
        height: 50px
        padding: 20px 35px

    .portfolio-home__action
        right: -10px
        bottom: -10px
        height: 50px
        padding: 15px 35px

@include medium
    .portfolio-home__frame
        padding: 140px 0 120px

    .portfolio-home__title
        font-size: 60px

    .portfolio-home__action
        padding: 15px 45px

@include small
    .portfolio-home__container
        padding: 3vh 5% 50px

    .portfolio-home__frame
        padding: 80px 0 70px

        &::after
            content: ''
            display: block
            position: absolute
            top: 0
            left: -10px
            background: $grey-300
            width: 10px
            height: 100%

    .portfolio-home__wrap
        width: 100vw
        margin-left: -26px

    .portfolio-home__list
        column-gap: 10px

    .portfolio-home__slide
        .post__title
            font-size: 12px
            line-height: 1.5
            opacity: 0
            padding-top: 10px
            transition: all 150ms ease

        &.is-active .post__title
            opacity: 1

    .portfolio-home__title
        font-size: 27px
        transform: translateY(30px)

    .portfolio-home__arrows
        display: none

    .portfolio-home__action
        width: 54%
        padding: 15px 20px

        svg
            margin-left: 10px

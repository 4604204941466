*, *:before, *:after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Light.woff2") format("woff2"), url("./fonts/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Bold.woff2") format("woff2"), url("./fonts/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-ExtraLight.woff2") format("woff2"), url("./fonts/Poppins-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.woff2") format("woff2"), url("./fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Thin.woff2") format("woff2"), url("./fonts/Poppins-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Medium.woff2") format("woff2"), url("./fonts/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-SemiBold.woff2") format("woff2"), url("./fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
body {
  font-family: "Poppins";
}

::selection {
  background-color: #EA5C27;
  color: #fff;
}

.body--clipping {
  overflow: hidden;
}

[v-cloak] {
  display: none;
}

.container {
  width: 90%;
  max-width: 1405px;
  margin: 0px auto;
}

@media (max-width: 1439px) {
  .container {
    width: 100%;
    max-width: 1030px;
    padding: 0 90px;
  }
}
@media (max-width: 767px) {
  .container {
    max-width: none;
    padding: 0 60px;
  }
}
@media (max-width: 479px) {
  .container {
    padding: 0 10%;
  }
}
.button {
  background: #EA5C27;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.22em;
  color: #F1F1F1;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  padding: 20px 40px;
  transition: all 300ms ease;
}
.button svg {
  transition: all 300ms ease;
}
.button:hover {
  background: #E3E3E3;
  color: #EA5C27;
}
.button:hover svg {
  color: #EA5C27;
}

.button--alt {
  position: relative;
  background: transparent;
}
.button--alt:hover {
  background: #EA5C27;
  color: #F1F1F1;
}
.button--alt::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border: 2px solid #F1F1F1;
  transition: all 200ms ease;
}

@media (max-width: 1439px) {
  .button {
    padding: 15px 20px;
  }
}
.header {
  display: flex;
  position: relative;
  z-index: 5;
  background: #F3F3F3;
  height: 115px;
}

.header__container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 90px;
  box-sizing: border-box;
  max-width: none;
}

.header__logo {
  height: 100%;
  align-self: center;
}

.header__social {
  display: none;
}

@media (max-width: 1439px) {
  .header {
    height: 80px;
  }

  .header__logo.logo {
    width: 140px;
    height: 40px;
  }
}
@media (max-width: 1279px) {
  .header__container {
    padding: 0 60px;
  }
}
@media (max-width: 1023px) {
  .header__social {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    flex: 1;
  }
  .header__social .social__list {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 15px;
    height: 100%;
  }
}
@media (max-width: 767px) {
  .header {
    height: 55px;
  }

  .header__container {
    padding: 0;
  }

  .header__logo.logo {
    width: 110px;
    height: 36px;
    margin-left: 60px;
  }

  .header__social {
    display: none;
  }
}
@media (max-width: 479px) {
  .header__logo.logo {
    width: 100px;
    height: 60px;
    margin-left: 10vw;
  }
}
.nav {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  height: 100%;
}

.nav__wrap {
  display: flex;
}

.nav__list {
  display: flex;
  justify-content: flex-end;
}

.nav__item {
  position: relative;
  display: inline-flex;
}

.nav__link, .nav__action {
  display: inline-flex;
  align-items: center;
  height: 100%;
  font-size: 12px;
  font-weight: 500;
  color: #444;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  background-color: #F3F3F3;
  background-image: linear-gradient(0deg, #EA5C27 50%, #F3F3F3 50%);
  background-size: 100% 200%;
  background-position: top center;
  text-decoration: none;
  padding: 0 35px;
  transition: all 300ms ease;
}

.nav__link:hover, .nav__action:hover {
  background-position: top 5% center;
}
.nav__link:hover .nav__icon, .nav__action:hover .nav__icon {
  color: #F1F1F1;
}
.nav__link:hover ~ .dropdown, .nav__action:hover ~ .dropdown {
  opacity: 1;
  transform: translateX(-50%) translateY(0%);
  pointer-events: all;
}

.nav__icon {
  flex-shrink: 0;
  width: 25px;
  height: 24px;
  margin-right: 10px;
  transition: all 400ms ease;
}

.nav__action {
  background: #E5E5E5;
  margin-left: 30px;
  padding: 0 55px;
}
.nav__action:hover {
  background: #EA5C27;
  color: #fff;
}
.nav__action:hover .nav__icon {
  color: #fff;
}
.nav__action span {
  display: inline-block;
  max-width: 80px;
}

.nav__item--dropdown .nav__link::after, .nav__item--dropdown .nav__action::after {
  content: "";
  display: block;
  background: url("./img/icons/chevron-down.svg") no-repeat center;
  width: 15px;
  height: 15px;
}
.nav__item--dropdown .nav__link:hover, .nav__item--dropdown .nav__action:hover {
  background-position: top center;
}

.nav__open {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90px;
  background: #fff;
  border: 0;
  cursor: pointer;
  margin-right: -90px;
  flex-shrink: 0;
}
.nav__open span {
  display: block;
  background: #EA5C27;
  width: 20px;
  height: 2px;
  margin-bottom: 8px;
}
.nav__open span:nth-child(2) {
  width: 14px;
  margin-left: -6px;
}
.nav__open span:last-child {
  margin-bottom: 0;
}

.nav__close {
  display: none;
}

.nav__social {
  display: none;
}

@media (max-width: 1439px) {
  .nav__link, .nav__action {
    letter-spacing: 0.2em;
    padding: 0 20px;
  }

  .nav__item--destaque .nav__link, .nav__item--destaque .nav__action {
    padding: 0 25px;
    margin-left: 15px;
  }
}
@media (max-width: 1279px) {
  .nav--open .nav__list {
    transform: translateX(0%);
  }
  .nav--open .nav__overlay {
    opacity: 1;
    pointer-events: all;
  }

  .nav__list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #666;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;
    width: 90vw;
    max-width: 550px;
    height: 100vh;
    overflow: auto;
    padding: 65px 62px;
    transform: translateX(100%);
    transition: all 400ms ease;
  }

  .nav__item--dropdown {
    display: flex;
    flex-direction: column;
    order: 5;
    margin-top: 15px;
  }
  .nav__item--dropdown .nav__link, .nav__item--dropdown .nav__action {
    position: relative;
    color: #C5C5C5;
  }
  .nav__item--dropdown .nav__link:after, .nav__item--dropdown .nav__action:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #444;
    width: 27px;
    height: 1px;
  }
  .nav__item--dropdown .dropdown {
    position: relative;
    top: auto;
    left: auto;
    opacity: 1 !important;
    pointer-events: all !important;
    transform: none !important;
  }
  .nav__item--dropdown .dropdown .dropdown__list {
    background: none;
    padding: 0;
    box-shadow: none;
  }
  .nav__item--dropdown .dropdown .dropdown__link {
    color: #fff;
    padding: 15px 0;
  }
  .nav__item--dropdown .dropdown .dropdown__link::after, .nav__item--dropdown .dropdown .dropdown__link::before {
    display: none;
  }

  .nav__link, .nav__action {
    width: 100%;
    background: transparent;
    font-size: 12px;
    color: #fff;
    padding: 15px 0;
  }

  .nav__action {
    background-color: #E5E5E5;
    background-image: linear-gradient(0deg, #EA5C27 50%, #E5E5E5 50%);
    background-size: 100% 200%;
    background-position: top center;
    color: #444;
    padding: 0 20px;
  }

  .nav__open {
    display: flex;
    margin-left: 0;
    margin-right: -60px;
  }

  .nav__close {
    display: block;
    position: absolute;
    top: 3%;
    right: 4%;
    width: 60px;
    height: 60px;
    background: none;
    border: 0;
    cursor: pointer;
  }
  .nav__close span {
    display: block;
    width: 20px;
    height: 2px;
  }

  .nav__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    opacity: 0;
    pointer-events: none;
    transition: all 400ms ease;
  }

  .nav__social {
    display: block;
    order: 6;
    margin-top: 30px;
  }
  .nav__social .social__list {
    display: flex;
    align-items: center;
  }
  .nav__social .social__list .social__item:not(:last-child) {
    margin-right: 18px;
  }
  .nav__social .social__list .social__link:hover .social__icon {
    color: #fff;
  }
  .nav__social .social__list .social__icon {
    color: #fff;
  }
}
@media (max-width: 1279px) {
  .nav__open {
    width: 60px;
  }
}
@media (max-width: 1023px) {
  .nav {
    flex: 0;
  }
}
@media (max-width: 767px) {
  .nav {
    flex: 1;
  }

  .nav__link, .nav__action {
    font-size: 12px;
  }

  .nav__icon {
    width: 18px;
  }

  .nav__open {
    margin-right: 0;
  }
  .nav__open span {
    margin-bottom: 6px;
  }

  .nav__action {
    flex: 0;
    font-size: 8px;
    letter-spacing: 0.1em;
    line-height: 1.2;
    margin-left: auto;
  }
}
@media (max-width: 479px) {
  .nav__open {
    width: 10vw;
  }
}
.logo {
  position: relative;
  width: 200px;
  height: 50px;
}
.logo span {
  display: none;
}

.logo__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.logo__link:hover .logo__icon {
  color: #EA5C27;
}

.logo__icon {
  width: 100%;
  height: 100%;
  color: #222;
  transition: all 300ms ease;
}

@media (max-width: 1439px) {
  .logo {
    width: 160px;
    height: 40px;
  }
}
.dropdown {
  position: absolute;
  top: 75%;
  left: 50%;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-50%) translateY(10%);
  transition: all 400ms ease;
}
.dropdown:hover {
  opacity: 1;
  transform: translateX(-50%) translateY(0%);
  pointer-events: all;
}

.dropdown__list {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 11px 14px rgba(0, 0, 0, 0.15);
  padding: 20px 0;
}

.dropdown__item:last-child .dropdown__link::after {
  display: none;
}

.dropdown__link {
  display: inline-block;
  position: relative;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.22em;
  color: #444;
  text-decoration: none;
  text-transform: uppercase;
  padding: 15px 30px;
}
.dropdown__link::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: #EA5C27;
  width: 0px;
  height: 100%;
  transition: all 300ms ease;
}
.dropdown__link::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  background: #E3E3E3;
  width: 15px;
  height: 1px;
}
.dropdown__link:hover::before {
  width: 6px;
}

.breadcrumb {
  display: flex;
  justify-content: flex-start;
  background: #666;
  padding: 10px 20px;
}

.breadcrumb__item,
.breadcrumb__link {
  font-size: 12px;
  color: #F1F1F1;
  font-weight: 400;
  letter-spacing: 0.22em;
  text-decoration: none;
}

.breadcrumb__link:hover {
  text-decoration: underline;
}

.breadcrumb__item:not(:first-child)::before {
  content: "/";
  margin: 0 4px;
}

@media (max-width: 1023px) {
  .breadcrumb__item,
.breadcrumb__link {
    font-size: 10px;
  }
}
@media (max-width: 375px) {
  .breadcrumb__item,
.breadcrumb__link {
    font-size: 8px;
  }
}
.social__list {
  display: grid;
  justify-content: center;
  justify-items: center;
}

.social__icon {
  transition: all 150ms ease;
}

.social__link:hover .social__icon {
  color: #EA5C27;
}

.card {
  display: grid;
  grid-template-rows: 1fr 3fr;
  row-gap: 30px;
}

.card__title {
  font-size: 24px;
  font-weight: 700;
  color: #444;
  border-left: 3px solid #E9B227;
  line-height: 1.3;
  padding: 2px 0 2px 15px;
  align-self: start;
}

.card__description {
  font-size: 16px;
  font-weight: 400;
  color: #444;
  line-height: 2;
}

@media (max-width: 1439px) {
  .card {
    row-gap: 10px;
  }

  .card__title {
    font-size: 16px;
  }

  .card__description {
    font-size: 14px;
  }
}
@media (max-width: 1023px) {
  .card__description {
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .card {
    row-gap: 16px;
  }

  .card__title {
    font-size: 18px;
  }
}
.post__link:hover .post__img {
  transform: scale(1.2);
}

.post__title {
  font-size: 14px;
  color: #444;
  padding-top: 30px;
}

.post__media {
  width: 100%;
  overflow: hidden;
}

.post__img {
  width: 100%;
  object-fit: cover;
  transition: all 2s ease;
}

.post__link {
  text-decoration: none;
}
.post__link .post__img,
.post__link .post__title,
.post__link .post__media {
  pointer-events: none;
}

@media (max-width: 1439px) {
  .post__title {
    padding-top: 20px;
  }
}
@media (max-width: 767px) {
  .post__title {
    padding-top: 10px;
  }
}
.box {
  display: flex;
  align-items: center;
  position: relative;
  background: #F3F3F3;
  font-size: 12px;
  font-weight: 600;
  color: #EA5C27;
  letter-spacing: 0.22em;
  text-decoration: none;
  border: 2px solid #EA5C27;
  padding: 4rem 6.25rem 4rem 2.5rem;
  transition: all 300ms ease;
}
.box::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background: #EA5C27 url("./img/arrow.png") no-repeat center;
  width: 50px;
  height: 50px;
  transition: all 300ms ease;
}

.box:hover {
  border-color: #233D1F;
}
.box:hover::after {
  background-color: #233D1F;
  background-position: top 10px right 10px;
}
.box:hover .box__icon {
  color: #233D1F;
}

.box__icon {
  width: 35px;
  height: 35px;
  color: #666;
  margin-right: 35px;
  transition: all 300ms ease;
}

.box__label {
  display: inline-flex;
  align-items: center;
  height: 45px;
  line-height: 1.5;
  border-left: 1px solid #B3B3B3;
  padding-left: 35px;
}

@media (max-width: 1439px) {
  .box {
    padding: 3rem 6.25rem 3rem 2.5rem;
  }

  .box__icon {
    margin-right: 20px;
  }
}
@media (max-width: 1279px) {
  .box__label {
    max-width: 120px;
    line-height: 1.5;
    margin-top: 15px;
  }
}
@media (max-width: 1023px) {
  .box {
    padding: 3rem 2rem 3rem 2rem;
  }

  .box__label {
    max-width: 150px;
    padding-left: 25px;
  }
}
@media (max-width: 767px) {
  .box {
    flex-direction: column;
    align-items: flex-start;
    padding: 10% 10% 6%;
  }
  .box::after {
    width: 35px;
    height: 35px;
    background-size: 10px;
  }

  .box__icon {
    width: 28px;
    height: 28px;
    margin-right: 0;
  }

  .box__label {
    border-left: 0;
    padding-left: 0;
  }
}
@media (max-width: 640px) {
  .box__label {
    max-width: none;
  }
}
@media (max-width: 479px) {
  .box__icon {
    width: 35px;
    height: 35px;
  }
}
.placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder__image {
  position: relative;
  opacity: 0;
  transition: all 300ms ease;
}

.placeholder__image--show {
  opacity: 1;
}

.pagination {
  display: flex;
  justify-content: space-between;
}

.pagination__pager {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  background: #EA5C27;
}
.pagination__pager:hover .pagination__icon {
  transform: translateX(-5px);
}

.pagination__icon {
  color: #F1F1F1;
  transition: all 300ms ease;
}

.pagination__pager--next:hover .pagination__icon {
  transform: rotate(180deg) translateX(-5px);
}
.pagination__pager--next .pagination__icon {
  transform: rotate(180deg);
}

.pagination__pages {
  display: flex;
}

.pagination__page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 68px;
  height: 68px;
  font-size: 14px;
  font-weight: 500;
  color: #EA5C27;
  text-decoration: none;
  border: 2px solid transparent;
  transition: all 150ms ease;
}
.pagination__page:not(:first-child) {
  margin-left: 10px;
}
.pagination__page:hover:not(.pagination__page--active) {
  background: #EA5C27;
  color: #F1F1F1;
}

.pagination__page--active {
  border-color: #C4C4C4;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background: transparent;
  pointer-events: none;
  opacity: 0;
  transform: scale(0.9);
  overflow-y: auto;
  transition: all 300ms ease;
}

.modal--show {
  background: #444;
  opacity: 1;
  pointer-events: all;
  transform: scale(1);
}

.modal__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 120px;
  box-sizing: border-box;
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  font-size: 40px;
  color: #E3E3E3;
  font-weight: 300;
  letter-spacing: 0.4em;
  text-transform: uppercase;
}

.modal__close {
  width: 60px;
  height: 55px;
  padding: 0;
}

.modal__content {
  display: flex;
  flex-direction: column;
  margin-top: 70px;
}

.modal__slide {
  width: 100%;
}

.modal__media {
  position: relative;
  width: 100%;
  height: calc(100vh - 260px);
}

.modal__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal__description {
  display: inline-block;
  width: 60%;
  font-size: 14px;
  color: #F1F1F1;
  line-height: 1.7;
  margin-top: 30px;
}

.modal__pagination.splide__pagination {
  left: auto;
  right: 0;
  bottom: -35px;
  padding: 0;
}

.modal__page.splide__pagination__page {
  background: #666;
  width: 35px;
  height: 5px;
  border-radius: 0;
  transform: none;
  opacity: 1;
  transition: background 150ms ease;
}
.modal__page.splide__pagination__page.is-active {
  background: #EA5C27;
  transform: none;
}

.modal__arrow.splide__arrow {
  left: -43px;
  background: #EA5C27;
  width: 68px;
  height: 68px;
  border-radius: 0;
  opacity: 1;
  transform: translateX(-100%) translateY(-50%);
}
.modal__arrow.splide__arrow:disabled {
  pointer-events: none;
  background: #222 !important;
}
.modal__arrow.splide__arrow:hover {
  opacity: 1;
}
.modal__arrow.splide__arrow svg {
  width: 30px;
  height: auto;
  color: #F1F1F1;
  fill: transparent;
  transition: all 150ms ease;
}

.modal__arrow--prev.splide__arrow--prev:hover svg {
  transform: translateX(-5px) scaleX(1);
}
.modal__arrow--prev.splide__arrow--prev svg {
  transform: scaleX(1);
}

.modal__arrow--next.splide__arrow--next {
  left: auto;
  right: -43px;
  transform: translateX(100%) translateY(-50%);
}
.modal__arrow--next.splide__arrow--next:hover svg {
  transform: translateX(5px) scaleX(-1);
}
.modal__arrow--next.splide__arrow--next svg {
  transform: scaleX(-1);
}

@media (max-width: 1279px) {
  .modal__title {
    font-size: 28px;
  }
}
@media (max-width: 1023px) {
  .modal__container {
    padding: 10% 10%;
  }

  .modal__arrows {
    display: none;
  }

  .modal__description {
    max-width: none;
    width: 100%;
    margin-top: 50px;
  }
}
@media (max-width: 767px) {
  .modal__header {
    flex-direction: column;
  }

  .modal__title {
    order: 2;
    margin-top: 10px;
  }

  .modal__close {
    align-self: flex-end;
  }

  .modal__content {
    margin-top: 50px;
  }
}
@media (max-width: 479px) {
  .modal__title {
    font-size: 20px;
  }

  .modal__close {
    width: 50px;
    height: 50px;
  }
}
.footer {
  width: 100%;
  background: #F3F3F3 url("./img/footer_bg.png") no-repeat bottom center;
  padding: 125px 90px 50px;
  background-blend-mode: darken;
}

.footer__title {
  font-size: 112px;
  font-weight: 100;
  color: #233D1F;
  line-height: 0.955;
  margin-left: 1.5rem;
}

.footer__intro {
  display: inline-block;
  font-size: 16px;
  color: #444;
  line-height: 2;
  margin: 60px 0 1.5rem 2rem;
}

.footer__main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;
  align-items: start;
}

.footer__list {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  justify-self: end;
  margin-top: 10%;
}

.footer__wrap {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  margin-top: 60px;
}

.footer__logo.logo {
  transition: all 300ms ease;
}
.footer__logo.logo .logo__icon {
  color: black !important;
}
.footer__logo.logo:hover {
  opacity: 1;
}

.footer__direitos {
  background: #F3F3F3;
  font-size: 10px;
  line-height: 2;
  letter-spacing: 0.21em;
  color: #666;
  padding: 10px 30px;
}

.footer__social .social__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 15px;
  margin-left: 115px;
}

@media (max-width: 1679px) {
  .footer__main {
    column-gap: 1.5rem;
  }
}
@media (max-width: 1439px) {
  .footer {
    padding-top: 60px;
  }

  .footer__title {
    font-size: 63px;
    margin-left: 1.75rem;
  }

  .footer__intro {
    font-size: 0.875rem;
  }

  .footer__main {
    grid-template-columns: 2fr 1fr;
    column-gap: 2rem;
  }

  .footer__logo {
    width: 120px;
    height: 35px;
  }

  .footer__direitos {
    font-size: 8px;
    padding: 6px 20px;
  }

  .footer__social .social__list {
    margin-left: 30px;
  }
}
@media (max-width: 1279px) {
  .footer {
    padding: 60px 0 40px;
  }

  .footer__wrap {
    padding: 0 60px;
  }

  .footer__intro {
    margin: 40px 0 20px 2rem;
  }

  .footer__list {
    margin-top: 3.45rem;
  }

  .footer__social .social__icon {
    width: 18px;
    height: 18px;
  }
}
@media (max-width: 1023px) {
  .footer__title {
    font-size: 53px;
    margin-left: 0.15rem;
  }

  .footer__intro {
    margin-left: 0.15rem;
  }

  .footer__main {
    grid-template-columns: 1fr;
  }

  .footer__list {
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 30px;
    justify-self: stretch;
  }

  .footer__logo {
    width: 100px;
    height: 28px;
  }
}
@media (max-width: 767px) {
  .footer {
    padding: 40px 0;
  }

  .footer__title {
    font-size: 48px;
    font-weight: 200;
    line-height: 1.2;
  }

  .footer__intro {
    max-width: none;
    line-height: 1.5;
    margin: 30px 0;
  }

  .footer__wrap {
    grid-template-columns: 1fr;
    row-gap: 35px;
    justify-items: center;
    padding: 0 5%;
    margin-top: 50px;
  }

  .footer__logo {
    width: 135px;
    height: 38px;
  }

  .footer__direitos {
    font-size: 7px;
    padding: 6px;
  }

  .footer__social {
    grid-row: 2/3;
  }
  .footer__social .social__list {
    margin-left: 0;
  }
}
@media (max-width: 640px) {
  .footer__title {
    font-size: 36px;
  }

  .footer__intro {
    margin: 30px 0 20px;
  }

  .footer__list {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 479px) {
  .footer__title {
    font-size: 32px;
  }

  .footer__social .social__icon {
    width: 30px;
    height: 30px;
  }

  .footer__direitos {
    font-size: 9px;
  }
}
@media (max-width: 375px) {
  .footer__intro {
    font-size: 12px;
    margin: 30px 0 10px;
  }
}
.input {
  display: grid;
  position: relative;
  min-width: 0;
}

.input--error .input__field {
  border-color: #EA5C27;
}

.input__label {
  color: #444;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2;
}

.input__field {
  width: 100%;
  background: #FFF;
  height: 42px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 2;
  border-radius: 0.25rem;
  border: 1px solid #B3B3B3;
  padding: 0.4rem 1rem;
}
.input__field:focus {
  border-color: #444;
  outline: none;
}

.input__message {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0.6875rem;
  color: #EA5C27;
  transform: translateY(125%);
}

@media (max-width: 1439px) {
  .input__label,
.input__field {
    font-size: 0.75rem;
  }
}
.select {
  display: grid;
  position: relative;
  min-width: 0;
}

.select--error .select__field {
  border-color: #EA5C27;
}

.select__label {
  color: #444;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2;
}

.select__wrap {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.select__wrap::after {
  content: "";
  display: block;
  width: 15px;
  height: 12px;
  background: url("/assets/img/icons/chevron-down-orange.svg") no-repeat center;
  background-size: contain;
  position: absolute;
  right: 0.75rem;
  pointer-events: none;
}

.select__field {
  background: #FFF;
  width: 100%;
  height: 42px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 2;
  appearance: none;
  border-radius: 0.25rem;
  border: 1px solid #B3B3B3;
  padding: 0 1rem;
  transition: color 150ms ease;
}

.select__field--default {
  color: #B3B3B3;
}

.select__message {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0.6875rem;
  color: #EA5C27;
  transform: translateY(125%);
}

@media (max-width: 1439px) {
  .select__label,
.select__field {
    font-size: 0.75rem;
  }
}
.textarea {
  display: grid;
  position: relative;
  min-width: 0;
}

.textarea--error .textarea__field {
  border-color: #EA5C27;
}

.textarea__label {
  color: #444;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2;
}

.textarea__field {
  background: #FFF;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border: 1px solid #B3B3B3;
  padding: 0.7rem 1rem;
  resize: vertical;
}
.textarea__field:focus {
  border-color: #444;
  outline: none;
}

.textarea__message {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 0.6875rem;
  color: #EA5C27;
  transform: translateY(125%);
}

@media (max-width: 1439px) {
  .textarea__label,
.textarea__field {
    font-size: 0.75rem;
  }
}
.form {
  display: grid;
  row-gap: 0.8rem;
  background: #F3F3F3;
  padding: 1.7rem 2rem;
}

.form__columns {
  display: grid;
  column-gap: 3.45rem;
  min-width: 0;
}

.form__columns--2-1 {
  grid-template-columns: 2fr 1fr;
}

.form__columns--1-2 {
  grid-template-columns: 1fr 2fr;
}

.form__footer {
  display: flex;
  justify-content: space-between;
  position: relative;
  font-size: 0.75rem;
  color: #444;
  font-weight: 400;
  line-height: 2;
  margin-top: 1rem;
}

.form__action {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #EA5C27;
  font-family: "Poppins";
  font-size: 0.75rem;
  color: #F1F1F1;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.165rem;
  text-transform: uppercase;
  cursor: pointer;
  border: 0;
  padding: 1rem 5rem;
  transition: background 200ms ease;
  overflow: hidden;
}
.form__action:hover {
  background: #233D1F;
}
.form__action:hover .form__icon {
  transform: translateX(20px);
}
.form__action span:first-child {
  position: relative;
  transition: transform 200ms ease;
}

.form__action--loading {
  pointer-events: none;
}
.form__action--loading .form__loading {
  transform: translateY(-220%);
}
.form__action--loading span {
  transform: translateY(-220%);
}

.form__icon {
  position: absolute;
  right: -30px;
  margin-left: 1rem;
  transition: transform 200ms ease;
}

.form__aviso {
  line-height: 1;
}

.form__recaptcha {
  position: relative;
  justify-self: start;
  background: #E5E5E5;
  border-radius: 6px;
  max-width: 70vw;
  overflow: hidden;
  padding: 0.25rem;
}

.form__recaptcha--error {
  box-shadow: 0px 0px 0px 1px #EA5C27;
}

.form__loading {
  display: inline-flex;
  position: absolute;
  width: 75px;
  height: 25px;
  align-items: center;
  bottom: -80%;
  transition: transform 200ms ease;
}
.form__loading__dot {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.form__loading__dot:nth-child(1) {
  left: 8px;
  animation: form__loading1 0.6s infinite;
}
.form__loading__dot:nth-child(2) {
  left: 8px;
  animation: form__loading2 0.6s infinite;
}
.form__loading__dot:nth-child(3) {
  left: 32px;
  animation: form__loading2 0.6s infinite;
}
.form__loading__dot:nth-child(4) {
  left: 56px;
  animation: form__loading3 0.6s infinite;
}

.form__progress {
  position: absolute;
  left: 0;
  bottom: -12px;
  width: 100%;
  height: 4px;
  background: #C4C4C4;
  opacity: 0;
  transition: opacity 150ms ease;
}

.form__progress--show {
  opacity: 1;
}

.form__bar {
  position: absolute;
  top: 0;
  left: 0;
  background: #EA5C27;
  width: 30%;
  height: 100%;
  transition: width 300ms ease;
}

.form__modal.swal2-popup {
  border-radius: 0;
  padding: 2.5rem 1rem;
}

.swal2-confirm.form__modal-button.swal2-styled {
  background-color: #EA5C27;
  padding: 0.8rem 3rem;
  border-radius: 0;
  box-shadow: none;
  transition: all 150ms ease;
}
.swal2-confirm.form__modal-button.swal2-styled:hover {
  background-color: #233D1F;
}
.swal2-confirm.form__modal-button.swal2-styled:focus {
  box-shadow: none;
}

@keyframes form__loading1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes form__loading3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes form__loading2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
@media (max-width: 1679px) {
  .form__columns {
    column-gap: 1rem;
  }

  .form__columns--2-1 {
    grid-template-columns: 1.5fr 1fr;
  }

  .form__columns--1-2 {
    grid-template-columns: 1fr 1.5fr;
  }
}
@media (max-width: 1439px) {
  .form__columns {
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }

  .form__footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
  }

  .form__action {
    min-width: 0;
  }

  .form__aviso {
    font-size: 0.6875rem;
  }
}
@media (max-width: 1023px) {
  .form {
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }
}
@media (max-width: 479px) {
  .form__footer {
    grid-template-columns: 1fr;
    row-gap: 1.5rem;
    justify-items: end;
    margin-top: 0;
  }

  .form__aviso {
    justify-self: start;
  }

  .form__columns {
    grid-template-columns: 1fr;
    row-gap: 0.8rem;
  }
}
@media (max-width: 375px) {
  .form__action {
    font-size: 0.6875rem;
  }
}
.uploader {
  display: grid;
  row-gap: 1rem;
  justify-content: start;
  justify-items: start;
}

.uploader__field {
  display: none;
}

.uploader__action {
  background: #EA5C27;
  font-family: "Poppins";
  border: 0;
  cursor: pointer;
  color: #F1F1F1;
  padding: 0.75rem 1.25rem;
  transition: background 150ms ease;
}
.uploader__action:hover {
  background: #233D1F;
  color: #F1F1F1;
}

.uploader__informacoes {
  display: grid;
  row-gap: 1rem;
  font-size: 0.75rem;
  list-style-type: none;
}
.uploader__informacoes code {
  background: rgba(234, 92, 39, 0.15);
  color: #EA5C27;
  font-weight: 600;
  border-radius: 6px;
  padding: 0.1rem 0.25rem;
}

.uploader__informacao {
  line-height: 1.5;
}

.uploader__arquivos {
  display: grid;
  row-gap: 0.3rem;
  list-style-type: none;
}

.uploader__arquivo {
  display: flex;
  justify-content: space-between;
  background: #E3E3E3;
}

.uploader__name {
  display: inline-block;
  font-size: 0.75rem;
  padding: 0.5rem;
}

.uploader__remover {
  background: #EA5C27;
  color: #F1F1F1;
  font-weight: bold;
  cursor: pointer;
  border: 0;
  height: 100%;
  padding: 0 0.5rem;
  transition: background 150ms ease;
}
.uploader__remover:hover {
  background: #222;
}

.banner {
  position: relative;
  z-index: 0;
  width: 100%;
  height: calc(100vh - 232px);
  overflow: hidden;
}

.banner__video {
  position: absolute;
  top: 50%;
  left: 0;
  background: #F3F3F3;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translateY(-50%);
  box-sizing: border-box;
  padding: 0 90px;
}

.banner__wrap {
  position: relative;
  z-index: 1;
  width: calc(100% - 180px);
  height: 100%;
  margin: 0px auto;
}

.banner__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  padding: 0 160px;
  margin: 0px auto;
}

.banner__title {
  display: inline-block;
  max-width: 660px;
  color: #F1F1F1;
  font-size: 60px;
  font-weight: 200;
  line-height: 1.3;
}

.banner__action {
  margin-top: 40px;
}

.banner__conheca {
  display: inline-flex;
  position: absolute;
  right: 0;
  bottom: 0;
  text-decoration: none;
  box-sizing: content-box;
  border: 2px solid #EA5C27;
  padding-left: 50px;
}
.banner__conheca span {
  display: inline-flex;
  align-items: center;
  max-width: 220px;
  font-size: 14px;
  font-weight: 400;
  color: #F1F1F1;
  line-height: 1.5;
  letter-spacing: 0.22em;
}
.banner__conheca::after {
  content: "";
  display: block;
  background: url("./img/icons/arrow.svg") no-repeat center, #EA5C27;
  width: 70px;
  height: 110px;
  margin-left: 50px;
  transition: background 300ms ease;
}
.banner__conheca:hover:after {
  background-position: center bottom 30px, center;
}

.banner__social {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 90px;
  height: 100%;
}
.banner__social .social__item:last-child {
  margin-top: 20px;
}
.banner__social:before, .banner__social:after {
  content: "";
  display: block;
  width: 1px;
  height: 160px;
  background: #C4C4C4;
}
.banner__social::before {
  margin-bottom: 45px;
}
.banner__social::after {
  margin-top: 45px;
}

@media (max-width: 1439px) {
  .banner {
    height: calc(100vh - 161px);
  }

  .banner__body {
    padding: 0 115px;
  }

  .banner__title {
    max-width: 400px;
    font-size: 40px;
  }

  .banner__conheca {
    padding-left: 35px;
  }
  .banner__conheca span {
    max-width: 180px;
    font-size: 12px;
  }
  .banner__conheca::after {
    width: 55px;
    height: 85px;
  }
}
@media (max-width: 1279px) {
  .banner__wrap {
    width: calc(100% - 120px);
  }

  .banner__body {
    padding: 90px;
  }

  .banner__video {
    padding: 0 60px;
  }

  .banner__social {
    width: 60px;
  }
}
@media (max-width: 1023px) {
  .banner__body {
    align-items: center;
  }

  .banner__title {
    text-align: center;
  }

  .banner__social {
    display: none;
  }
}
@media (max-width: 767px) {
  .banner {
    height: calc(100vh - 110px);
  }

  .banner__wrap {
    width: 100%;
  }

  .banner__video {
    padding: 0;
  }

  .banner__body {
    padding: 90px 50px;
  }

  .banner__title {
    max-width: 250px;
    font-size: 24px;
  }

  .banner__conheca {
    border: 0;
  }
  .banner__conheca span {
    max-width: 140px;
    font-size: 10px;
    text-align: right;
    letter-spacing: 0.1em;
  }
  .banner__conheca::after {
    width: 35px;
    height: 55px;
    background-size: 10px;
    margin-left: 20px;
  }
}
@media screen and (max-aspect-ratio: 163/65) and (max-width: 1023px) {
  .banner__body {
    align-items: flex-start;
  }

  .banner__title {
    text-align: left;
  }

  .banner__conheca {
    border: 0;
  }
  .banner__conheca span {
    max-width: 140px;
    font-size: 10px;
    text-align: right;
    letter-spacing: 0.1em;
  }
  .banner__conheca::after {
    width: 35px;
    height: 55px;
    background-size: 10px;
    margin-left: 20px;
  }
}
.vejamais {
  display: inline-flex;
  align-items: center;
  background: #F3F3F3;
  width: 100%;
  height: 115px;
  padding: 0 115px;
}

.vejamais__link {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  color: #EA5C27;
  letter-spacing: 0.22em;
  text-decoration: none;
}
.vejamais__link::before {
  content: "";
  display: block;
  background: #666;
  position: absolute;
  top: -90px;
  right: -35px;
  width: 2px;
  height: 140px;
  pointer-events: none;
  transition: all 150ms ease;
}
.vejamais__link:hover {
  color: #444;
}

@media (max-width: 1439px) {
  .vejamais {
    height: 80px;
  }

  .vejamais__link {
    font-size: 9px;
  }
  .vejamais__link::before {
    top: -95px;
    right: -25px;
  }
}
@media (max-width: 1279px) {
  .vejamais {
    padding: 0 60px;
  }
}
@media (max-width: 1023px) {
  .vejamais {
    display: none;
  }
}
.sobre {
  position: relative;
  background: #F3F3F3;
  width: 100%;
  padding: 40px 90px 50px;
  box-sizing: border-box;
}

.sobre__intro {
  position: absolute;
  top: 0;
  left: 90px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.7em;
  border-right: 5px solid #EA5C27;
  padding-right: 30px;
  margin-top: 130px;
  transform: rotate(-90deg) translate(-100%, 0%);
  transform-origin: top left;
}

.sobre__container {
  position: relative;
}

.sobre__wrap {
  position: relative;
  top: 80px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  height: 45vh;
}

.sobre__video {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 62%;
  height: 100%;
  text-decoration: none;
}
.sobre__video:hover .sobre__img {
  opacity: 0.6;
  filter: brightness(100%);
  transform: scale(1.05);
}
.sobre__video:hover .sobre__action:after {
  backdrop-filter: blur(20px);
  background: #EA5C27;
  border-color: #EA5C27;
  transform: rotate(45deg);
}
.sobre__video:hover .sobre__controls svg {
  color: #F1F1F1;
}
.sobre__video:hover .sobre__controls span {
  color: #233D1F;
}
.sobre__video:hover .sobre__controls span::before {
  width: 20px;
  opacity: 0;
}

.sobre__controls {
  position: relative;
  display: flex;
  align-items: center;
}
.sobre__controls span {
  position: absolute;
  left: 60px;
  display: inline-flex;
  align-items: center;
  z-index: 1;
  font-size: 14px;
  font-weight: 600;
  color: #F1F1F1;
  letter-spacing: 0.615em;
}
.sobre__controls span::before {
  content: "";
  display: block;
  background: #F1F1F1;
  width: 115px;
  height: 1px;
  margin-right: 30px;
  transition: width 300ms ease, opacity 100ms ease;
}

.sobre__action {
  width: 60px;
  height: 60px;
  background: transparent;
  border: 0;
  z-index: 1;
  pointer-events: none;
  padding: 0;
}
.sobre__action svg {
  width: 20px;
  height: 20px;
  color: #EA5C27;
  margin-left: 5px;
}
.sobre__action::after {
  background: #F1F1F1;
}

.sobre__img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  filter: brightness(80%);
  object-fit: cover;
  transform: scale(1);
  transition: all 300ms ease;
}

.sobre__title {
  position: relative;
  z-index: 1;
  font-size: 60px;
  font-weight: 200;
  color: #444;
  line-height: 1.3;
  pointer-events: none;
}

.sobre__ambientes {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  font-size: 22px;
  color: #EA5C27;
  line-height: 2.5;
  letter-spacing: 1.6em;
  transform: translateX(60%);
}
.sobre__ambientes::before {
  content: "";
  display: block;
  background: #E9B227;
  width: 20px;
  height: 4px;
  margin-bottom: 1.3em;
}

.sobre__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 160px;
  margin-top: 150px;
}

@media (max-width: 1439px) {
  .sobre__intro {
    font-size: 10px;
    border-right-width: 3px;
    padding-right: 10px;
  }

  .sobre__controls span {
    left: 40px;
    font-size: 12px;
  }
  .sobre__controls span::before {
    width: 50px;
  }

  .sobre__action {
    width: 40px;
    height: 40px;
  }
  .sobre__action svg {
    width: 8px;
    margin-left: 2px;
  }
  .sobre__action::after {
    border-width: 1px;
  }

  .sobre__title {
    font-size: 35px;
    max-width: 470px;
  }

  .sobre__ambientes {
    font-size: 15px;
    transform: translate(20%, -5%);
  }

  .sobre__list {
    column-gap: 115px;
    margin-top: 200px;
  }
}
@media (max-width: 1279px) {
  .sobre {
    padding: 40px 60px 50px;
  }

  .sobre__intro {
    left: 60px;
  }

  .sobre__ambientes {
    font-size: 12px;
    transform: translate(5%, 5%);
  }

  .sobre__list {
    column-gap: 50px;
  }
}
@media (max-width: 1023px) {
  .sobre__wrap {
    grid-template-columns: 1fr;
    height: auto;
  }

  .sobre__ambientes {
    top: 230px;
  }

  .sobre__title {
    height: 200px;
  }

  .sobre__video {
    position: relative;
    width: 100%;
    height: 325px;
  }

  .sobre__list {
    grid-template-columns: 1fr;
    row-gap: 50px;
    margin-top: 150px;
  }
}
@media (max-width: 767px) {
  .sobre {
    padding: 50px 0;
  }

  .sobre__intro {
    position: relative;
    top: auto;
    left: auto;
    border: 0;
    letter-spacing: 0.3em;
    padding: 10px 0;
    margin-top: 0;
    margin-left: 10%;
    transform: none;
  }
  .sobre__intro::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: #EA5C27;
    width: 15px;
    height: 2px;
  }

  .sobre__ambientes {
    display: none;
  }

  .sobre__wrap {
    top: auto;
  }

  .sobre__title {
    max-width: 300px;
    height: auto;
    font-size: 19px;
    margin: 20px 0 30px;
  }

  .sobre__video {
    height: auto;
    padding: 28.125% 0;
  }

  .sobre__controls span {
    position: static;
  }
  .sobre__controls span::before {
    opacity: 1 !important;
    width: 28px !important;
    margin-right: 12px;
  }

  .sobre__img {
    opacity: 0.3 !important;
    transform: scale(1) !important;
  }

  .sobre__action::after {
    backdrop-filter: none !important;
    transform: none !important;
  }

  .sobre__list {
    margin-top: 50px;
  }
}
.portfolio-home {
  background: #F3F3F3;
}

.portfolio-home__container {
  width: 100%;
  max-width: none;
  padding: 10vh 90px 175px;
  box-sizing: border-box;
}

.portfolio-home__title {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  font-size: 110px;
  font-weight: 100;
  color: #666;
  text-align: center;
  letter-spacing: 0.065em;
  transform: translateY(50px);
}

.portfolio-home__frame {
  position: relative;
  background: #F3F3F3;
  border: 20px solid #E3E3E3;
  padding: 200px 0 120px;
  z-index: 2;
}

.portfolio-home__wrap {
  width: calc(100vw - 127px);
}

.portfolio-home__list {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  column-gap: 55px;
}

.portfolio-home__splide.splide {
  position: static;
  width: 100%;
}

.portfolio-home__track.splide__track {
  position: static;
}

.portfolio-home__arrows {
  display: flex;
  position: absolute;
  left: -20px;
  bottom: -20px;
}

.portfolio-home__arrow.splide__arrow {
  position: static;
  display: inline;
  background: #E3E3E3;
  width: auto;
  height: 70px;
  border: 1px solid #F3F3F3;
  border-radius: 0;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  padding: 20px 40px;
  transform: translateY(0%);
}
.portfolio-home__arrow.splide__arrow svg {
  fill: none;
  width: auto;
  height: auto;
  transform: scaleX(1);
  transition: all 300ms ease;
}
.portfolio-home__arrow.splide__arrow:hover {
  background: #EA5C27;
}
.portfolio-home__arrow.splide__arrow:hover svg {
  color: #F1F1F1;
}

.portfolio-home__arrow--next.splide__arrow--next svg {
  transform: scaleX(-1);
}

.portfolio-home__action {
  display: inline-flex;
  align-items: center;
  height: 70px;
  position: absolute;
  right: -20px;
  bottom: -20px;
}
.portfolio-home__action svg {
  margin-left: 45px;
}
.portfolio-home__action:hover {
  color: #F1F1F1;
  background: #EA5C27;
}
.portfolio-home__action:hover svg {
  color: #F1F1F1;
  transform: rotate(180deg);
}

@media (max-width: 1439px) {
  .portfolio-home__container {
    padding-bottom: 105px;
  }

  .portfolio-home__frame {
    border-width: 12px;
    padding: 160px 0 140px;
  }

  .portfolio-home__title {
    font-size: 80px;
    letter-spacing: 0.3em;
    transform: translateY(40px);
  }

  .portfolio-home__arrows {
    left: -12px;
    bottom: -12px;
  }

  .portfolio-home__arrow.splide__arrow {
    height: 55px;
    padding: 20px 40px;
  }
  .portfolio-home__arrow.splide__arrow svg {
    width: 35px;
  }

  .portfolio-home__action {
    right: -12px;
    bottom: -12px;
    height: 55px;
    padding: 15px 40px;
  }
  .portfolio-home__action svg {
    width: 16px;
    margin-left: 20px;
  }
}
@media (max-width: 1279px) {
  .portfolio-home__container {
    padding: 10vh 60px 105px;
  }

  .portfolio-home__frame {
    border-width: 10px;
  }

  .portfolio-home__arrows {
    left: -10px;
    bottom: -10px;
  }

  .portfolio-home__arrow.splide__arrow {
    height: 50px;
    padding: 20px 35px;
  }

  .portfolio-home__action {
    right: -10px;
    bottom: -10px;
    height: 50px;
    padding: 15px 35px;
  }
}
@media (max-width: 1023px) {
  .portfolio-home__frame {
    padding: 140px 0 120px;
  }

  .portfolio-home__title {
    font-size: 60px;
  }

  .portfolio-home__action {
    padding: 15px 45px;
  }
}
@media (max-width: 767px) {
  .portfolio-home__container {
    padding: 3vh 5% 50px;
  }

  .portfolio-home__frame {
    padding: 80px 0 70px;
  }
  .portfolio-home__frame::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -10px;
    background: #E3E3E3;
    width: 10px;
    height: 100%;
  }

  .portfolio-home__wrap {
    width: 100vw;
    margin-left: -26px;
  }

  .portfolio-home__list {
    column-gap: 10px;
  }

  .portfolio-home__slide .post__title {
    font-size: 12px;
    line-height: 1.5;
    opacity: 0;
    padding-top: 10px;
    transition: all 150ms ease;
  }
  .portfolio-home__slide.is-active .post__title {
    opacity: 1;
  }

  .portfolio-home__title {
    font-size: 27px;
    transform: translateY(30px);
  }

  .portfolio-home__arrows {
    display: none;
  }

  .portfolio-home__action {
    width: 54%;
    padding: 15px 20px;
  }
  .portfolio-home__action svg {
    margin-left: 10px;
  }
}
.ambientes-home {
  position: relative;
  z-index: 1;
  width: 100%;
  background: #233D1F;
  padding: 50px 90px 80px;
}
.ambientes-home::before, .ambientes-home::after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  left: 0;
  z-index: 3;
  background: #233D1F;
  width: 50vw;
  height: 280px;
  transform: translateY(-100%);
}
.ambientes-home::after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
  z-index: 3;
  width: 77vw;
  height: 165px;
  transform: translateY(100%);
}

.ambientes-home__container {
  width: 100%;
}

.ambientes-home__intro {
  position: absolute;
  top: 70px;
  left: 90px;
  transform: rotate(-90deg) translateY(100%) translateX(-93%);
  transform-origin: bottom left;
  letter-spacing: 0.7em;
  border-right: 5px solid #EA5C27;
  padding-right: 20px;
  color: #F1F1F1;
}

.ambientes-home__title {
  display: inline-block;
  max-width: 350px;
  font-size: 30px;
  font-weight: 300;
  line-height: 1.3;
  color: #F1F1F1;
}

.ambientes-home__list {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 150px;
}

.ambientes-home__item {
  position: relative;
  padding: 3% 0;
}
.ambientes-home__item--active .ambientes-home__media {
  opacity: 1;
}

.ambientes-home__media {
  position: absolute;
  width: 100%;
  height: 250px;
  top: 50%;
  left: 0;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity 500ms ease;
}

.ambientes-home__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  filter: brightness(0.75);
}

.ambientes-home__link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  font-size: 120px;
  color: #F1F1F1;
  font-weight: 100;
  letter-spacing: -0.05em;
  text-decoration: none;
}

@media (max-width: 1439px) {
  .ambientes-home {
    padding-top: 0;
  }
  .ambientes-home::after {
    height: 95px;
  }

  .ambientes-home__intro {
    top: 15px;
    font-size: 10px;
    border-right-width: 2px;
  }

  .ambientes-home__title {
    max-width: 180px;
    font-size: 16px;
  }

  .ambientes-home__list {
    margin-top: 120px;
  }

  .ambientes-home__link {
    font-size: 68px;
  }

  .ambientes-home__media {
    height: 145px;
  }
}
@media (max-width: 1279px) {
  .ambientes-home {
    padding: 0 60px 80px;
  }

  .ambientes-home__intro {
    left: 60px;
  }
}
@media (max-width: 1023px) {
  .ambientes-home::after {
    width: 80vw;
  }
}
@media (max-width: 767px) {
  .ambientes-home {
    padding: 30px 0 50px;
  }
  .ambientes-home::before {
    height: 120px;
  }
  .ambientes-home::after {
    width: 50vw;
    height: 50px;
  }

  .ambientes-home__container {
    width: 80%;
  }

  .ambientes-home__intro {
    position: relative;
    top: auto;
    left: auto;
    color: #666;
    letter-spacing: 0.3em;
    border: 0;
    transform: none;
    padding: 8px 0;
  }
  .ambientes-home__intro::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: #EA5C27;
    width: 15px;
    height: 2px;
  }

  .ambientes-home__title {
    margin-top: 20px;
  }

  .ambientes-home__media {
    display: none;
  }

  .ambientes-home__list {
    margin-top: 45px;
    align-items: flex-start;
  }

  .ambientes-home__link {
    font-size: 34px;
    padding: 10px 0;
  }
}
.parceiros {
  position: relative;
  width: 100%;
  background: #F3F3F3;
  padding: 50px 90px 150px;
}

.parceiros__container {
  display: grid;
  grid-template-columns: 340px 1fr;
  width: 100%;
  padding-top: 190px;
}

.parceiros__title {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  font-size: 30px;
  font-weight: 400;
  color: #222;
  padding: 0 40%;
  line-height: 1.3;
  z-index: 1;
}
.parceiros__title::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 155%;
  border: 20px solid #EA5C27;
  transform: translateY(-35%);
}

.parceiros__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.parceiros__item {
  width: 100%;
  max-height: 150px;
}

.parceiros__link {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.parceiros__link:hover .parceiros__img {
  opacity: 1;
}

.parceiros__img {
  width: 100%;
  max-width: 150px;
  max-height: 180px;
  object-fit: contain;
  mix-blend-mode: darken;
  opacity: 1;
  transition: all 300ms ease;
}

@media (max-width: 1439px) {
  .parceiros {
    padding-bottom: 90px;
  }

  .parceiros__container {
    grid-template-columns: 205px 1fr;
    column-gap: 40px;
    padding-top: 100px;
  }

  .parceiros__title {
    height: 200px;
    font-size: 18px;
    padding: 70px 20% 0;
  }
  .parceiros__title::after {
    width: 100%;
    height: 168%;
    border: 10px solid #EA5C27;
    transform: translateY(-33.5%);
  }

  .parceiros__list {
    row-gap: 30px;
  }

  .parceiros__item {
    height: 80px;
  }

  .parceiros__img {
    max-width: 90px;
    max-height: 100px;
  }
}
@media (max-width: 1279px) {
  .parceiros__container {
    max-width: 1405px;
    margin-left: 60px;
  }
}
@media (max-width: 1023px) {
  .parceiros {
    padding: 50px 40px 90px 70px;
  }

  .parceiros__container {
    max-width: none;
    margin-left: auto;
  }
}
@media (max-width: 767px) {
  .parceiros {
    padding: 20px 0;
  }

  .parceiros__container {
    grid-template-columns: 1fr;
    padding: 0 10%;
  }

  .parceiros__title {
    max-width: 180px;
    justify-self: center;
    text-align: center;
    line-height: 1.5;
    padding: 65px 30px;
  }
  .parceiros__title::after {
    left: 50%;
    height: 100%;
    transform: translateX(-50%);
  }

  .parceiros__list {
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
  }

  .parceiros__item {
    height: 55px;
  }

  .parceiros__img {
    max-width: 90px;
  }
}
.home__cinema {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 10% 12%;
  z-index: 5;
  background: #666;
  pointer-events: none;
  opacity: 0;
  transform: scale(0.9);
  transition: all 300ms ease;
}
.home__cinema--show {
  opacity: 1;
  transform: scale(1);
  pointer-events: all;
}

.home__wrap {
  position: relative;
  width: 130vh;
  padding-top: 73.125vh;
}

.home__video {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.home__close {
  position: absolute;
  top: 5%;
  right: 5%;
  width: 60px;
  height: 55px;
  padding: 0;
}

@media (max-aspect-ratio: 137/85) {
  .home__wrap {
    width: 100%;
    padding-top: 56.25%;
  }
}
.ambientes__content {
  background: #F3F3F3;
  width: 100%;
  padding: 0 90px;
  margin: 0px auto;
}

.ambientes__wrap {
  position: relative;
  height: 345px;
  padding: 115px 0;
}

.ambientes__banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.ambientes__banner::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.4;
}

.ambientes__img {
  width: 100%;
  height: 345px;
  object-fit: cover;
  filter: brightness(0.65);
}

.ambientes__container {
  position: relative;
  z-index: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10%;
  row-gap: 20px;
  align-content: center;
}

.ambientes__title {
  grid-column: 1/2;
  align-self: end;
  font-size: 60px;
  font-weight: 200;
  color: #F1F1F1;
  text-transform: uppercase;
}

.ambientes__breadcrumb {
  grid-column: 1/2;
  justify-self: start;
  align-self: start;
  text-transform: uppercase;
}

.ambientes__description {
  grid-column: 2/3;
  grid-row: 1/3;
  font-size: 16px;
  color: #F1F1F1;
  line-height: 2;
}

.ambientes__moveis {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.ambientes__movel.post {
  display: grid;
  grid-template-rows: 1fr auto;
  border-left: 1px solid #C4C4C4;
  border-bottom: 1px solid #C4C4C4;
  padding: 170px 70px 70px;
}
.ambientes__movel.post:last-child, .ambientes__movel.post:nth-child(3n) {
  border-right: 1px solid #C4C4C4;
}
.ambientes__movel.post:last-child {
  width: calc(100% + 1px);
}
.ambientes__movel.post:nth-child(3n) {
  width: 100%;
}
.ambientes__movel.post:nth-child(n+4) {
  padding-top: 30px;
}
.ambientes__movel.post .post__title {
  font-size: 22px;
  color: #444;
  margin-bottom: 35px;
  text-transform: uppercase;
}
.ambientes__movel.post .post__media {
  position: relative;
  padding-top: 100%;
}
.ambientes__movel.post .post__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1439px) {
  .ambientes__content {
    padding: 0 60px 60px;
  }

  .ambientes__wrap {
    padding: 80px 0;
  }

  .ambientes__movel.post {
    padding: 100px 50px 50px;
  }
  .ambientes__movel.post .post__title {
    font-size: 18px;
  }
}
@media (max-width: 1279px) {
  .ambientes__movel.post {
    padding: 80px 40px 40px;
  }
  .ambientes__movel.post .post__title {
    font-size: 16px;
  }

  .ambientes__wrap {
    padding: 60px 0;
  }
}
@media (max-width: 1023px) {
  .ambientes__title {
    font-size: 48px;
  }

  .ambientes__moveis {
    grid-template-columns: repeat(2, 1fr);
  }

  .ambientes__movel.post:last-child, .ambientes__movel.post:nth-child(3n) {
    border-right: none;
  }
  .ambientes__movel.post:nth-child(n+4) {
    padding-top: none;
  }
  .ambientes__movel.post:last-child, .ambientes__movel.post:nth-child(2n) {
    border-right: 1px solid #C4C4C4;
  }
  .ambientes__movel.post:last-child {
    width: calc(100% + 1px);
  }
  .ambientes__movel.post:nth-child(2n) {
    width: 100%;
  }
  .ambientes__movel.post:nth-child(n+3) {
    padding-top: 30px;
  }
}
@media (max-width: 767px) {
  .ambientes__container {
    grid-template-columns: 1fr;
  }

  .ambientes__description {
    grid-column: 1/2;
    grid-row: unset;
  }

  .ambientes__wrap {
    height: auto;
  }

  .ambientes__img {
    height: 100%;
  }

  .simpleParallax {
    height: 100%;
  }

  .ambientes__wrap {
    padding: 40px 0;
  }

  .ambientes__title {
    font-size: 32px;
  }

  .ambientes__moveis {
    grid-template-columns: 1fr;
  }

  .ambientes__movel.post {
    border-right: 1px solid #C4C4C4;
  }
  .ambientes__movel.post:last-child {
    width: 100%;
  }
  .ambientes__movel.post:nth-child(2n) {
    width: 100%;
  }
  .ambientes__movel.post:nth-child(n+3) {
    padding-top: 0;
  }
  .ambientes__movel.post:not(:first-child) {
    padding-top: 30px;
  }
}
@media (max-width: 479px) {
  .ambientes__content {
    padding: 0 10% 50px;
  }

  .ambientes__movel.post {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 375px) {
  .ambientes__title {
    font-size: 28px;
  }

  .ambientes__movel.post {
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .ambientes__movel.post:not(:first-child) {
    padding-top: 30px;
  }
  .ambientes__movel.post .post__title {
    margin-bottom: 20px;
  }
}
.portfolio .nav__action {
  background: #F3F3F3;
}
.portfolio .nav__action:hover {
  background: #EA5C27;
}

.portfolio__header.header {
  background: #E3E3E3;
}
.portfolio__header.header .nav__link {
  background-color: #E3E3E3;
  background-image: linear-gradient(0deg, #EA5C27 50%, #E3E3E3 50%);
}
.portfolio__header.header .nav__item--destaque .nav__link {
  background-color: #F3F3F3;
  background-image: linear-gradient(0deg, #EA5C27 50%, #F3F3F3 50%);
}

.portfolio__content {
  background: #F3F3F3;
}

.portfolio__intro {
  background: #E3E3E3;
  padding: 0 90px 230px;
}

.portfolio__title {
  font-size: 212px;
  color: #233D1F;
  font-weight: 100;
  letter-spacing: 0.065em;
  text-align: center;
  border: 3px solid #F3F3F3;
  padding: 125px 0;
}

.portfolio__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 140px;
  row-gap: 115px;
  margin-top: -80px;
}

.portfolio__pagination {
  margin-top: 150px;
}

@media (max-width: 1439px) {
  .portfolio__content {
    padding-bottom: 70px;
  }

  .portfolio__intro {
    padding: 0 90px 150px;
  }

  .portfolio__title {
    font-size: 140px;
    padding: 80px 0;
  }

  .portfolio__list {
    column-gap: 60px;
    row-gap: 75px;
  }

  .portfolio__header.header .nav__link {
    background: transparent;
  }
}
@media (max-width: 1279px) {
  .portfolio__title {
    font-size: 110px;
    padding: 60px 0;
  }

  .portfolio__intro {
    padding: 0 60px 150px;
  }
}
@media (max-width: 1023px) {
  .portfolio__content {
    padding-bottom: 20px;
  }

  .portfolio__title {
    font-size: 90px;
  }
}
@media (max-width: 767px) {
  .portfolio__title {
    padding: 40px 0;
  }

  .portfolio__intro {
    padding: 0 60px 120px;
  }

  .portfolio__list {
    margin-top: -60px;
    row-gap: 50px;
  }

  .portfolio__content {
    padding-bottom: 1;
  }

  .portfolio .footer {
    padding-top: 0;
  }
}
@media (max-width: 640px) {
  .portfolio__title {
    font-size: 56px;
  }

  .portfolio__list {
    grid-template-columns: 1fr;
  }

  .portfolio__content {
    padding-bottom: 60px;
  }
}
@media (max-width: 479px) {
  .portfolio__title {
    font-size: 48px;
  }

  .portfolio__intro {
    padding: 0 10% 180px;
  }

  .portfolio__list {
    grid-template-columns: 1fr;
    margin-top: -120px;
  }

  .portfolio__content {
    padding-bottom: 80px;
  }
}
@media (max-width: 375px) {
  .portfolio__title {
    font-size: 36px;
  }
}
.error {
  background: #F3F3F3;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.error__container {
  display: grid;
  max-width: 960px;
  grid-template-columns: 1fr 1fr;
}

.error__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #444;
}

.error__logo {
  width: 150px;
  margin-bottom: 60px;
}
.error__logo svg {
  color: #EA5C27;
}
.error__logo .logo__link:hover svg {
  color: #222;
}

.error__title {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 30px;
}

.error__code {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}

.error__message {
  max-width: 300px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 45px;
}

.error__action {
  color: #444;
  border: 2px solid #EA5C27;
  padding: 0.9rem 1.3rem;
}

.error__img {
  position: relative;
  display: flex;
  justify-content: center;
}

.error__porta {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1023px) {
  .error__title {
    font-size: 32px;
  }
}
@media (max-width: 767px) {
  .error__container {
    grid-template-columns: 1fr;
  }

  .error__img {
    display: none;
  }
}
@media (max-width: 479px) {
  .error {
    height: auto;
    min-height: 100vh;
    padding-top: 20px;
    box-sizing: border-box;
  }
}
$font-primary: 'Poppins'

$white: #F1F1F1

$grey-200: #F3F3F3
$grey-250: #E5E5E5
$grey-300: #E3E3E3
$grey-350: #C4C4C4
$grey-360: #C5C5C5
$grey-400: #B3B3B3
$grey-500: #666
$grey-600: #444
$grey-800: #222

$orange-500: #EA5C27
$yellow-500: #E9B227
$green-800: #233D1F
$color-primary: $orange-500
$color-secondary: $yellow-500
$color-tertiary: $green-800

@use '../_variables' as *
@use '../_mixins' as *

.textarea
    display: grid
    position: relative
    min-width: 0

.textarea--error
    .textarea__field
        border-color: $color-primary

.textarea__label
    color: $grey-600
    font-size: 0.875rem
    font-weight: 400
    line-height: 2

.textarea__field
    background: #FFF
    font-family: $font-primary
    font-weight: 400
    font-size: 0.875rem
    line-height: 1.5
    border-radius: 0.25rem
    border: 1px solid #B3B3B3
    padding: 0.7rem 1rem
    resize: vertical

    &:focus
        border-color: $grey-600
        outline: none

.textarea__message
    position: absolute
    right: 0
    bottom: 0
    font-size: 0.6875rem
    color: $color-primary
    transform: translateY(125%)

@include larger
    .textarea__label,
    .textarea__field
        font-size: 0.75rem
@use '../../variables' as *
@use '../../_mixins' as *

.ambientes__content
    background: $grey-200
    width: 100%
    padding: 0 90px
    margin: 0px auto

.ambientes__wrap
    position: relative
    height: 345px
    padding: 115px 0

.ambientes__banner
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

    &::before
        content: ''
        display: block
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: #000
        opacity: 0.4

.ambientes__img
    width: 100%
    height: 345px
    object-fit: cover
    filter: brightness(0.65)

.ambientes__container
    position: relative
    z-index: 1
    display: grid
    grid-template-columns: 1fr 1fr
    column-gap: 10%
    row-gap: 20px
    align-content: center

.ambientes__title
    grid-column: 1/2
    align-self: end
    font-size: 60px
    font-weight: 200
    color: $white
    text-transform: uppercase

.ambientes__breadcrumb
    grid-column: 1/2
    justify-self: start
    align-self: start
    text-transform: uppercase

.ambientes__description
    grid-column: 2/3
    grid-row: 1/3
    font-size: 16px
    color: $white
    line-height: 2

.ambientes__moveis
    display: grid
    grid-template-columns: repeat(3, 1fr)

.ambientes__movel.post
    display: grid
    grid-template-rows: 1fr auto
    border-left: 1px solid $grey-350
    border-bottom: 1px solid $grey-350
    padding: 170px 70px 70px

    &:last-child,
    &:nth-child(3n)
        border-right: 1px solid $grey-350

    &:last-child
        width: calc(100% + 1px)

    &:nth-child(3n)
        width: 100%

    &:nth-child(n+4)
        padding-top: 30px

    .post__title
        font-size: 22px
        color: $grey-600
        margin-bottom: 35px
        text-transform: uppercase

    .post__media
        position: relative
        padding-top: 100%

    .post__img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

@include larger
    .ambientes__content
        padding: 0 60px 60px

    .ambientes__wrap
        padding: 80px 0

    .ambientes__movel.post
        padding: 100px 50px 50px

        .post__title
            font-size: 18px

@include large
    .ambientes__movel.post
        padding: 80px 40px 40px

        .post__title
            font-size: 16px

    .ambientes__wrap
        padding: 60px 0

@include medium
    .ambientes__title
        font-size: 48px

    .ambientes__moveis
        grid-template-columns: repeat(2, 1fr)

    .ambientes__movel.post
        &:last-child,
        &:nth-child(3n)
            border-right: none

        &:nth-child(n+4)
            padding-top: none

        &:last-child,
        &:nth-child(2n)
            border-right: 1px solid $grey-350

        &:last-child
            width: calc(100% + 1px)

        &:nth-child(2n)
            width: 100%

        &:nth-child(n+3)
            padding-top: 30px

@include small
    .ambientes__container
        grid-template-columns: 1fr

    .ambientes__description
        grid-column: 1/2
        grid-row: unset

    .ambientes__wrap
        height: auto

    .ambientes__img
        height: 100%

    .simpleParallax
        height: 100%

    .ambientes__wrap
        padding: 40px 0

    .ambientes__title
        font-size: 32px

    .ambientes__moveis
        grid-template-columns: 1fr

    .ambientes__movel.post
        border-right: 1px solid $grey-350

        &:last-child
            width: 100%

        &:nth-child(2n)
            width: 100%

        &:nth-child(n+3)
            padding-top: 0

        &:not(:first-child)
            padding-top: 30px

@include smaller
    .ambientes__content
        padding: 0 10% 50px

    .ambientes__movel.post
        padding-left: 30px
        padding-right: 30px
        padding-bottom: 30px

@include smallest
    .ambientes__title
        font-size: 28px

    .ambientes__movel.post
        padding-top: 50px
        padding-left: 20px
        padding-right: 20px
        padding-bottom: 20px

        &:not(:first-child)
            padding-top: 30px

        .post__title
            margin-bottom: 20px

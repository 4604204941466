@use '../_variables' as *
@use '../_mixins' as *

.nav
    display: flex
    justify-content: flex-end
    flex: 1
    height: 100%

.nav__wrap
    display: flex

.nav__list
    display: flex
    justify-content: flex-end

.nav__item
    position: relative
    display: inline-flex

.nav__link
    display: inline-flex
    align-items: center
    height: 100%
    font-size: 12px
    font-weight: 500
    color: $grey-600
    letter-spacing: 0.22em
    text-transform: uppercase
    background-color: $grey-200
    background-image: linear-gradient(0deg, $color-primary 50%, $grey-200 50%)
    background-size: 100% 200%
    background-position: top center
    text-decoration: none
    padding: 0 35px
    transition: all 300ms ease

.nav__link:hover
    background-position: top 5% center

    .nav__icon
        color: $white

    & ~ .dropdown
        opacity: 1
        transform: translateX(-50%) translateY(0%)
        pointer-events: all

.nav__icon
    flex-shrink: 0
    width: 25px
    height: 24px
    margin-right: 10px
    transition: all 400ms ease

.nav__action
    @extend .nav__link
    background: $grey-250
    margin-left: 30px
    padding: 0 55px

    &:hover
        background: $color-primary
        color: #fff

        .nav__icon
            color: #fff

    span
        display: inline-block
        max-width: 80px

.nav__item--dropdown
    .nav__link
        &::after
            content: ''
            display: block
            background: url('./img/icons/chevron-down.svg') no-repeat center
            width: 15px
            height: 15px

        &:hover
            background-position: top center

.nav__open
    display: none
    flex-direction: column
    justify-content: center
    align-items: center
    width: 90px
    background: #fff
    border: 0
    cursor: pointer
    margin-right: -90px
    flex-shrink: 0

    span
        display: block
        background: $color-primary
        width: 20px
        height: 2px
        margin-bottom: 8px

        &:nth-child(2)
            width: 14px
            margin-left: -6px

        &:last-child
            margin-bottom: 0

.nav__close
    display: none

.nav__social
    display: none

@include larger
    .nav__link
        letter-spacing: 0.2em
        padding: 0 20px

    .nav__item--destaque .nav__link
        padding: 0 25px
        margin-left: 15px

@include large
    .nav--open
        .nav__list
            transform: translateX(0%)

        .nav__overlay
            opacity: 1
            pointer-events: all

    .nav__list
        display: flex
        flex-direction: column
        justify-content: flex-start
        background: $grey-500
        position: fixed
        top: 0
        right: 0
        z-index: 5
        width: 90vw
        max-width: 550px
        height: 100vh
        overflow: auto
        padding: 65px 62px
        transform: translateX(100%)
        transition: all 400ms ease

    .nav__item--dropdown
        display: flex
        flex-direction: column
        order: 5
        margin-top: 15px

        .nav__link
            position: relative
            color: $grey-360

            &:after
                content: ''
                display: block
                position: absolute
                left: 0
                bottom: 0
                background: $grey-600
                width: 27px
                height: 1px

        .dropdown
            position: relative
            top: auto
            left: auto
            opacity: 1 !important
            pointer-events: all !important
            transform: none !important

            .dropdown__list
                background: none
                padding: 0
                box-shadow: none

            .dropdown__link
                color: #fff
                padding: 15px 0

                &::after,
                &::before
                    display: none

    .nav__link
        width: 100%
        background: transparent
        font-size: 12px
        color: #fff
        padding: 15px 0

    .nav__action
        background-color: $grey-250
        background-image: linear-gradient(0deg, $color-primary 50%, $grey-250 50%)
        background-size: 100% 200%
        background-position: top center
        color: $grey-600
        padding: 0 20px

    .nav__open
        display: flex
        margin-left: 0
        margin-right: -60px

    .nav__close
        display: block
        position: absolute
        top: 3%
        right: 4%
        width: 60px
        height: 60px
        background: none
        border: 0
        cursor: pointer

        span
            display: block
            width: 20px
            height: 2px

    .nav__overlay
        position: fixed
        top: 0
        left: 0
        width: 100vw
        height: 100vh
        background: rgba(0,0,0,0.8)
        cursor: pointer
        opacity: 0
        pointer-events: none
        transition: all 400ms ease

    .nav__social
        display: block
        order: 6
        margin-top: 30px

        .social__list
            display: flex
            align-items: center

            .social__item:not(:last-child)
                margin-right: 18px

            .social__link:hover .social__icon
                color: #fff

            .social__icon
                color: #fff

@include large
    .nav__open
        width: 60px

@include medium
    .nav
        flex: 0

@include small
    .nav
        flex: 1

    .nav__link
        font-size: 12px

    .nav__icon
        width: 18px

    .nav__open
        margin-right: 0

        span
            margin-bottom: 6px

    .nav__action
        flex: 0
        font-size: 8px
        letter-spacing: 0.1em
        line-height: 1.2
        margin-left: auto

@include smaller
    .nav__open
        width: 10vw

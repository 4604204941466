@use '../_variables' as *
@use '../_mixins' as *

.select
    display: grid
    position: relative
    min-width: 0

.select--error
    .select__field
        border-color: $color-primary

.select__label
    color: $grey-600
    font-size: 0.875rem
    font-weight: 400
    line-height: 2

.select__wrap
    display: flex
    align-items: center
    position: relative
    width: 100%

    &::after
        content: ''
        display: block
        width: 15px
        height: 12px
        background: url('/assets/img/icons/chevron-down-orange.svg') no-repeat center
        background-size: contain
        position: absolute
        right: 0.75rem
        pointer-events: none

.select__field
    background: #FFF
    width: 100%
    height: 42px
    font-family: $font-primary
    font-weight: 400
    font-size: 0.875rem
    line-height: 2
    appearance: none
    border-radius: 0.25rem
    border: 1px solid #B3B3B3
    padding: 0 1rem
    transition: color 150ms ease

.select__field--default
    color: $grey-400

.select__message
    position: absolute
    right: 0
    bottom: 0
    font-size: 0.6875rem
    color: $color-primary
    transform: translateY(125%)

@include larger
    .select__label,
    .select__field
        font-size: 0.75rem
@use '../_variables' as *

.pagination
    display: flex
    justify-content: space-between

.pagination__pager
    display: flex
    justify-content: center
    align-items: center
    width: 68px
    height: 68px
    background: $color-primary

    &:hover
        .pagination__icon
            transform: translateX(-5px)

.pagination__icon
    color: $white
    transition: all 300ms ease

.pagination__pager--next
    &:hover
        .pagination__icon
            transform: rotate(180deg) translateX(-5px)

    .pagination__icon
        transform: rotate(180deg)

.pagination__pages
    display: flex

.pagination__page
    display: flex
    justify-content: center
    align-items: center
    width: 68px
    height: 68px
    font-size: 14px
    font-weight: 500
    color: $color-primary
    text-decoration: none
    border: 2px solid transparent
    transition: all 150ms ease

    &:not(:first-child)
        margin-left: 10px

    &:hover:not(.pagination__page--active)
        background: $color-primary
        color: $white

.pagination__page--active
    border-color: $grey-350

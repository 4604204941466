@use '../../_variables' as *
@use '../../_mixins' as *

.ambientes-home
    position: relative
    z-index: 1
    width: 100%
    background: $color-tertiary
    padding: 50px 90px 80px

    &::before,
    &::after
        content: ''
        display: block
        position: absolute
        top: 1px
        left: 0
        z-index: 3
        background: $color-tertiary
        width: 50vw
        height: 280px
        transform: translateY(-100%)

    &::after
        top: auto
        bottom: 0
        left: auto
        right: 0
        z-index: 3
        width: 77vw
        height: 165px
        transform: translateY(100%)


.ambientes-home__container
    width: 100%

.ambientes-home__intro
    position: absolute
    top: 70px
    left: 90px
    transform: rotate(-90deg) translateY(100%) translateX(-93%)
    transform-origin: bottom left
    letter-spacing: 0.7em
    border-right: 5px solid $color-primary
    padding-right: 20px
    color: $white

.ambientes-home__title
    display: inline-block
    max-width: 350px
    font-size: 30px
    font-weight: 300
    line-height: 1.3
    color: $white

.ambientes-home__list
    display: flex
    width: 100%
    flex-direction: column
    margin-top: 150px

.ambientes-home__item
    position: relative
    padding: 3% 0

    &--active
        .ambientes-home__media
            opacity: 1

.ambientes-home__media
    position: absolute
    width: 100%
    height: 250px
    top: 50%
    left: 0
    opacity: 0
    transform: translateY(-50%)
    transition: opacity 500ms ease

.ambientes-home__img
    width: 100%
    height: 100%
    object-fit: cover
    opacity: 0.7
    filter: brightness(0.75)

.ambientes-home__link
    display: inline-flex
    justify-content: center
    align-items: center
    width: 100%
    height: 100%
    position: relative
    z-index: 1
    font-size: 120px
    color: $white
    font-weight: 100
    letter-spacing: -0.05em
    text-decoration: none


@include larger
    .ambientes-home
        padding-top: 0
            
        &::after
            height: 95px

    .ambientes-home__intro
        top: 15px
        font-size: 10px
        border-right-width: 2px

    .ambientes-home__title
        max-width: 180px
        font-size: 16px

    .ambientes-home__list
        margin-top: 120px

    .ambientes-home__link
        font-size: 68px

    .ambientes-home__media
        height: 145px

@include large
    .ambientes-home
        padding: 0 60px 80px

    .ambientes-home__intro
        left: 60px

@include medium
    .ambientes-home
        &::after
            width: 80vw

@include small
    .ambientes-home
        padding: 30px 0 50px

        &::before
            height: 120px

        &::after
            width: 50vw
            height: 50px

    .ambientes-home__container
        width: 80%

    .ambientes-home__intro
        position: relative
        top: auto
        left: auto
        color: $grey-500
        letter-spacing: 0.3em
        border: 0
        transform: none
        padding: 8px 0

        &::before
            content: ''
            display: block
            position: absolute
            top: 0
            left: 0
            background: $color-primary
            width: 15px
            height: 2px

    .ambientes-home__title
        margin-top: 20px

    .ambientes-home__media
        display: none

    .ambientes-home__list
        margin-top: 45px
        align-items: flex-start

    .ambientes-home__link
        font-size: 34px
        padding: 10px 0
@use '../_variables' as *
@use '../_mixins' as *

.card
    display: grid
    grid-template-rows: 1fr 3fr
    row-gap: 30px

.card__title
    font-size: 24px
    font-weight: 700
    color: $grey-600
    border-left: 3px solid $color-secondary
    line-height: 1.3
    padding: 2px 0 2px 15px
    align-self: start

.card__description
    font-size: 16px
    font-weight: 400
    color: $grey-600
    line-height: 2

@include larger
    .card
        row-gap: 10px

    .card__title
        font-size: 16px

    .card__description
        font-size: 14px

@include medium
    .card__description
        padding-left: 20px

@include small
    .card
        row-gap: 16px

    .card__title
        font-size: 18px
@use '../../_variables' as *
@use '../../_mixins' as *

.vejamais
    display: inline-flex
    align-items: center
    background: $grey-200
    width: 100%
    height: 115px
    padding: 0 115px

.vejamais__link
    position: relative
    font-size: 14px
    font-weight: 600
    color: $color-primary
    letter-spacing: 0.22em
    text-decoration: none

    &::before
        content: ''
        display: block
        background: $grey-500
        position: absolute
        top: -90px
        right: -35px
        width: 2px
        height: 140px
        pointer-events: none
        transition: all 150ms ease

    &:hover
        color: $grey-600

@include larger
    .vejamais
        height: 80px

    .vejamais__link
        font-size: 9px

        &::before
            top: -95px
            right: -25px

@include large
    .vejamais
        padding: 0 60px

@include medium
    .vejamais
        display: none
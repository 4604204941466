@use '../_variables' as *
@use '../_mixins' as *

.footer
    width: 100%
    background: $grey-200 url('./img/footer_bg.png') no-repeat bottom center
    padding: 125px 90px 50px
    background-blend-mode: darken

.footer__title
    font-size: 112px
    font-weight: 100
    color: $color-tertiary
    line-height: 0.955
    margin-left: 1.5rem

.footer__intro
    display: inline-block
    font-size: 16px
    color: $grey-600
    line-height: 2
    margin: 60px 0 1.5rem 2rem

.footer__main
    display: grid
    grid-template-columns: 1fr 1fr
    column-gap: 3rem
    align-items: start

.footer__list
    display: grid
    grid-template-columns: 1fr
    row-gap: 1rem
    justify-self: end
    margin-top: 10%

.footer__wrap
    display: grid
    grid-template-columns: 1fr auto auto
    align-items: center
    margin-top: 60px

.footer__logo.logo
    transition: all 300ms ease

    .logo__icon
        color: black !important

    &:hover
        opacity: 1

.footer__direitos
    background: $grey-200
    font-size: 10px
    line-height: 2
    letter-spacing: 0.21em
    color: $grey-500
    padding: 10px 30px

.footer__social .social__list
    display: grid
    grid-template-columns: 1fr 1fr
    align-items: center
    column-gap: 15px
    margin-left: 115px

@include largest
    .footer__main
        column-gap: 1.5rem

@include larger
    .footer
        padding-top: 60px

    .footer__title
        font-size: 63px
        margin-left: 1.75rem

    .footer__intro
        font-size: 0.875rem
    
    .footer__main
        grid-template-columns: 2fr 1fr
        column-gap: 2rem

    .footer__logo
        width: 120px
        height: 35px

    .footer__direitos
        font-size: 8px
        padding: 6px 20px

    .footer__social .social__list
        margin-left: 30px

        

@include large
    .footer
        padding: 60px 0 40px

    .footer__wrap
        padding: 0 60px

    .footer__intro
        margin: 40px 0 20px 2rem

    .footer__list
        margin-top: 3.45rem

    .footer__social .social__icon
        width: 18px
        height: 18px

@include medium
    .footer__title
        font-size: 53px
        margin-left: 0.15rem

    .footer__intro
        margin-left: 0.15rem

    .footer__main
        grid-template-columns: 1fr

    .footer__list
        grid-template-columns: 1fr 1fr
        column-gap: 1rem
        row-gap: 30px
        justify-self: stretch

    .footer__logo
        width: 100px
        height: 28px

@include small
    .footer
        padding: 40px 0

    .footer__title
        font-size: 48px
        font-weight: 200
        line-height: 1.2

    .footer__intro
        max-width: none
        line-height: 1.5
        margin: 30px 0

    .footer__wrap
        grid-template-columns: 1fr
        row-gap: 35px
        justify-items: center
        padding: 0 5%
        margin-top: 50px

    .footer__logo
        width: 135px
        height: 38px

    .footer__direitos
        font-size: 7px
        padding: 6px

    .footer__social
        grid-row: 2/3

        .social__list
            margin-left: 0

@include smalle
    .footer__title
        font-size: 36px

    .footer__intro
        margin: 30px 00 20px

    .footer__list
        grid-template-columns: 1fr

@include smaller
    .footer__title
        font-size: 32px

    .footer__social
        .social__icon
            width: 30px
            height: 30px

    .footer__direitos
        font-size: 9px

@include smallest
    .footer__intro
        font-size: 12px
        margin: 30px 0 10px

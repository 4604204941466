@use '../_variables' as *

.dropdown
    position: absolute
    top: 75%
    left: 50%
    width: 100%
    opacity: 0
    pointer-events: none
    transform: translateX(-50%) translateY(10%)
    transition: all 400ms ease

    &:hover
        opacity: 1
        transform: translateX(-50%) translateY(0%)
        pointer-events: all

.dropdown__list
    background: #fff
    border-radius: 4px
    box-shadow: 0px 11px 14px rgba(0, 0, 0, 0.15)
    padding: 20px 0

.dropdown__item:last-child .dropdown__link
    &::after
        display: none

.dropdown__link
    display: inline-block
    position: relative
    width: 100%
    font-size: 12px
    font-weight: 500
    letter-spacing: 0.22em
    color: $grey-600
    text-decoration: none
    text-transform: uppercase
    padding: 15px 30px

    &::before
        content: ''
        display: block
        position: absolute
        top: 0
        left: 0
        background: $color-primary
        width: 0px
        height: 100%
        transition: all 300ms ease

    &::after
        content: ''
        display: block
        position: absolute
        bottom: 0
        background: $grey-300
        width: 15px
        height: 1px

    &:hover
        &::before
            width: 6px
@use '../../_variables' as *
@use '../../_mixins' as *

.banner
    position: relative
    z-index: 0
    width: 100%
    height: calc(100vh - 232px)
    overflow: hidden

.banner__video
    position: absolute
    top: 50%
    left: 0
    background: $grey-200
    width: 100%
    height: 100%
    object-fit: cover
    transform: translateY(-50%)
    box-sizing: border-box
    padding: 0 90px

.banner__wrap
    position: relative
    z-index: 1
    width: calc(100% - 180px)
    height: 100%
    margin: 0px auto

.banner__body
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start
    height: 100%
    padding: 0 160px
    margin: 0px auto

.banner__title
    display: inline-block
    max-width: 660px
    color: $white
    font-size: 60px
    font-weight: 200
    line-height: 1.3

.banner__action
    margin-top: 40px

.banner__conheca
    display: inline-flex
    position: absolute
    right: 0
    bottom: 0
    text-decoration: none
    box-sizing: content-box
    border: 2px solid $color-primary
    padding-left: 50px

    span
        display: inline-flex
        align-items: center
        max-width: 220px
        font-size: 14px
        font-weight: 400
        color: $white
        line-height: 1.5
        letter-spacing: 0.22em

    &::after
        content: ''
        display: block
        background: url('./img/icons/arrow.svg') no-repeat center, $color-primary
        width: 70px
        height: 110px
        margin-left: 50px
        transition: background 300ms ease

    &:hover:after
        background-position: center bottom 30px, center

.banner__social
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    position: absolute
    width: 90px
    height: 100%

    .social__item:last-child
        margin-top: 20px

    &:before,
    &:after
        content: ''
        display: block
        width: 1px
        height: 160px
        background: $grey-350

    &::before
        margin-bottom: 45px

    &::after
        margin-top: 45px

@include larger
    .banner
        height: calc(100vh - 161px)

    .banner__body
        padding: 0 115px

    .banner__title
        max-width: 400px
        font-size: 40px

    .banner__conheca
        padding-left: 35px

        span
            max-width: 180px
            font-size: 12px

        &::after
            width: 55px
            height: 85px

@include large
    .banner__wrap
        width: calc(100% - 120px)

    .banner__body
        padding: 90px

    .banner__video
        padding: 0 60px

    .banner__social
        width: 60px

@include medium
    .banner__body
        align-items: center

    .banner__title
        text-align: center

    .banner__social
        display: none

@include small
    .banner
        height: calc(100vh - 110px)

    .banner__wrap
        width: 100%

    .banner__video
        padding: 0

    .banner__body
        padding: 90px 50px

    .banner__title
        max-width: 250px
        font-size: 24px

    .banner__conheca
        border: 0

        span
            max-width: 140px
            font-size: 10px
            text-align: right
            letter-spacing: 0.1em

        &::after
            width: 35px
            height: 55px
            background-size: 10px
            margin-left: 20px

@media screen and (max-aspect-ratio: 163/65) and (max-width: 1023px)
    .banner__body
        align-items: flex-start

    .banner__title
        text-align: left

    .banner__conheca
        border: 0

        span
            max-width: 140px
            font-size: 10px
            text-align: right
            letter-spacing: 0.1em

        &::after
            width: 35px
            height: 55px
            background-size: 10px
            margin-left: 20px
@use '../../_variables' as *
@use '../../_mixins' as *
@use 'sass:math'

.sobre
    position: relative
    background: $grey-200
    width: 100%
    padding: 40px 90px 50px
    box-sizing: border-box

.sobre__intro
    position: absolute
    top: 0
    left: 90px
    font-size: 16px
    font-weight: 400
    line-height: 1.5
    letter-spacing: 0.7em
    border-right: 5px solid $color-primary
    padding-right: 30px
    margin-top: 130px
    transform: rotate(-90deg) translate(-100%, 0%)
    transform-origin: top left

.sobre__container
    position: relative

.sobre__wrap
    position: relative
    top: 80px
    display: grid
    grid-template-columns: 2fr 1fr
    align-items: center
    height: 45vh

.sobre__video
    display: flex
    justify-content: center
    align-items: center
    position: absolute
    top: 0
    right: 0
    width: 62%
    height: 100%
    text-decoration: none

    &:hover
        .sobre__img
            opacity: 0.6
            filter: brightness(100%)
            transform: scale(1.05)

        .sobre__action:after
            backdrop-filter: blur(20px)
            background: $color-primary
            border-color: $color-primary
            transform: rotate(45deg)

        .sobre__controls 
            svg
                color: $white

            span
                color: $color-tertiary

            span::before
                width: 20px
                opacity: 0

.sobre__controls
    position: relative
    display: flex
    align-items: center

    span
        position: absolute
        left: 60px
        display: inline-flex
        align-items: center
        z-index: 1
        font-size: 14px
        font-weight: 600
        color: $white
        letter-spacing: 0.615em

        &::before
            content: ''
            display: block
            background: $white
            width: 115px
            height: 1px
            margin-right: 30px
            transition: width 300ms ease, opacity 100ms ease

.sobre__action
    width: 60px
    height: 60px
    background: transparent
    border: 0
    z-index: 1
    pointer-events: none
    padding: 0

    svg
        width: 20px
        height: 20px
        color: $color-primary
        margin-left: 5px

    &::after
        background: $white

.sobre__img
    position: absolute
    top: 0
    right: 0
    width: 100%
    height: 100%
    opacity: 0.5
    filter: brightness(80%)
    object-fit: cover
    transform: scale(1)
    transition: all 300ms ease

.sobre__title
    position: relative
    z-index: 1
    font-size: 60px
    font-weight: 200
    color: $grey-600
    line-height: 1.3
    pointer-events: none

.sobre__ambientes
    position: absolute
    top: 0
    right: 0
    z-index: 2
    font-size: 22px
    color: $color-primary
    line-height: 2.5
    letter-spacing: 1.6em
    transform: translateX(60%)

    &::before
        content: ''
        display: block
        background: $color-secondary
        width: 20px
        height: 4px
        margin-bottom: 1.3em

.sobre__list
    display: grid
    grid-template-columns: repeat(3, 1fr)
    column-gap: 160px
    margin-top: 150px

@include larger
    .sobre__intro
        font-size: 10px
        border-right-width: 3px
        padding-right: 10px

    .sobre__controls
        span
            left: 40px
            font-size: 12px

            &::before
                width: 50px

    .sobre__action
        width: 40px
        height: 40px

        svg
            width: 8px
            margin-left: 2px

        &::after
            border-width: 1px

    .sobre__title
        font-size: 35px
        max-width: 470px

    .sobre__ambientes
        font-size: 15px
        transform: translate(20%, -5%)

    .sobre__list
        column-gap: 115px
        margin-top: 200px

@include large
    .sobre
        padding: 40px 60px 50px

    .sobre__intro
        left: 60px

    .sobre__ambientes
        font-size: 12px
        transform: translate(5%, 5%)

    .sobre__list
        column-gap: 50px

@include medium
    .sobre__wrap
        grid-template-columns: 1fr
        height: auto

    .sobre__ambientes
        top: 230px

    .sobre__title
        height: 200px

    .sobre__video
        position: relative
        width: 100%
        height: 325px

    .sobre__list
        grid-template-columns: 1fr
        row-gap: 50px
        margin-top: 150px

@include small
    .sobre
        padding: 50px 0

    .sobre__intro
        position: relative
        top: auto
        left: auto
        border: 0
        letter-spacing: 0.3em
        padding: 10px 0
        margin-top: 0
        margin-left: 10%
        transform: none

        &::before
            content: ''
            display: block
            position: absolute
            top: 0
            left: 0
            background: $color-primary
            width: 15px
            height: 2px

    .sobre__ambientes
        display: none

    .sobre__wrap
        top: auto

    .sobre__title
        max-width: 300px
        height: auto
        font-size: 19px
        margin: 20px 0 30px

    .sobre__video
        height: auto
        padding: math.div(9, 16) * 50% 0

    .sobre__controls
        span
            position: static

            &::before
                opacity: 1 !important
                width: 28px !important
                margin-right: 12px

    .sobre__img
        opacity: 0.3 !important
        transform: scale(1) !important

    .sobre__action::after
        backdrop-filter: none !important
        transform: none !important

    .sobre__list
        margin-top: 50px

// Media Queries
$default-screen: 1919px
$largest-screen: 1679px
$larger-screen: 1439px
$large-screen: 1279px
$medium-screen: 1023px
$small-screen: 767px
$smalle-screen: 640px
$smaller-screen: 479px
$smallest-screen: 375px

@mixin default
    @media (max-width: #{$default-screen})
        @content

@mixin largest
    @media (max-width: #{$largest-screen})
        @content

@mixin larger
    @media (max-width: #{$larger-screen})
        @content

@mixin large
    @media (max-width: #{$large-screen})
        @content

@mixin medium
    @media (max-width: #{$medium-screen})
        @content

@mixin small
    @media (max-width: #{$small-screen})
        @content

@mixin smalle
    @media (max-width: #{$smalle-screen})
        @content

@mixin smaller
    @media (max-width: #{$smaller-screen})
        @content

@mixin smallest
    @media (max-width: #{$smallest-screen})
        @content
